import React, { useCallback } from 'react'
import './Login.css'
import config from '../../config.js'
import { Button, Checkbox, Form, Input, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import axios from 'axios';

export default function Login(props) {
    const onFinish = (values) => {
        // 调用登录接口
        console.log(values)
        axios.get(config.authApi,
            {params:{username:values.username,password:values.password}
        }).then(res=>{
            console.log(res)
            if(res.data.status ===1 ){
                message.error("username or password error")
                return 
            }else{
                localStorage.setItem("token",res.data.token)
                localStorage.setItem("username",values.username)
                localStorage.setItem("userid",res.data.userid)
                localStorage.setItem("userrole",res.data.type)
                props.history.push("/")
            }
        })
        props.history.push("/login")
      }
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);
  
    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);

  return (
    <div style={{background:'rgb(35,39,65)',height:'100%'}}>
        <Particles id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
          background: {
              color: {
                  value: "#0d47a1",
              },
          },
          fpsLimit: 120,
          interactivity: {
              events: {
                  onClick: {
                      enable: true,
                      mode: "push",
                  },
                  onHover: {
                      enable: true,
                      mode: "repulse",
                  },
                  resize: true,
              },
              modes: {
                  push: {
                      quantity: 4,
                  },
                  repulse: {
                      distance: 200,
                      duration: 0.4,
                  },
              },
          },
          particles: {
              color: {
                  value: "#ffffff",
              },
              links: {
                  color: "#ffffff",
                  distance: 200,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
              },
              collisions: {
                  enable: true,
              },
              move: {
                  directions: "none",
                  enable: true,
                  outModes: {
                      default: "bounce",
                  },
                  random: false,
                  speed: 2,
                  straight: false,
              },
              number: {
                  density: {
                      enable: true,
                      area: 1000,
                  },
                  value: 60,
              },
              opacity: {
                  value: 0.7,
              },
              shape: {
                  type: "circle",
              },
              size: {
                  value: { min: 1, max: 2 },
              },
          },
          detectRetina: true,
      }} />
        <div className="formContainer">
        
            <div className="loginTitle"> MVP Admin System</div>
        <Form
            name="normal_login"
            className="login-form"
            // initialValues={{ remember: true }}
            onFinish={onFinish}
            >
            <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your Username!' }]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
            >
                <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="密码"
                />
            </Form.Item>
            

            <Form.Item >
                <div className="login-form-button-list">
                    
                    <Button type='primary' htmlType="submit" className="login-form-button1" >
                    Login
                    </Button>
                    
                </div>
                
            </Form.Item>
            </Form>
        </div>
    </div>
  )
}
