import React,{useEffect, useState,useRef} from 'react'
import { Layout, Menu } from 'antd';
import {
    UploadOutlined,
    UserOutlined,
    HeartOutlined,
    VideoCameraOutlined
  } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './menu.css'
import * as iwareUtils from '../../util/common'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faVideoCamera,faWheelchair,faMedkit,faUserNurse,faTools,faFolderOpen,faStethoscope} from '@fortawesome/free-solid-svg-icons'

const patientIcon=<FontAwesomeIcon icon={faWheelchair}/>
const videoIcon=<FontAwesomeIcon icon={faVideoCamera}/>
const sessionIcon=<FontAwesomeIcon icon={faMedkit} />
const kinIcon=<FontAwesomeIcon icon={faUserNurse} />
const adminIcon=<FontAwesomeIcon icon={faTools} />
const resourceIcon=<FontAwesomeIcon icon={faFolderOpen} />
const assessmentIcon=<FontAwesomeIcon icon={faStethoscope} />

const { Sider } = Layout;

function SideMenu(props) {
  const [authorizedMenuItem, setauthorizedMenuItem] = useState([])
  const items=[
    {
    key: '/session',
    icon: sessionIcon,
    label: 'Sessions',
    // permistion:['0','1'],
    children:[
        {
            key:'/session/sessionlist',
            // icon: <UserOutlined />,
            label: 'Exercise Video ',
            permistion:['0','1'],
        }
    ]
    },
    {
    key: '/patient',
    icon: patientIcon,
    label: 'Patient',

    children:[
        {
            key:'/patient/exercisevideolist',
            // icon: <UserOutlined />,
            label: 'Video Re-Test',
            permistion:['0','1','2'],
        },
        {
            key:'/patient/patientlist',
            // icon: <UserOutlined />,
            label: 'Patients List',
            permistion:['0','1','2'],
        },
        {
            key:'/patient/patientcliniclog',
            // icon: <UserOutlined />,
            label: 'Patient ClinicLog',
            permistion:['0','1','2'],
        }
    ]
    },
    {
      key: '/kin',
      icon: kinIcon,
      label: 'Kinesiologist',
      children:[
          {
              key:'/kin/kinlist',
              // icon: <UserOutlined />,
              label: 'Kin List',
              permistion:['0','1','2'],
          }
      ]
    },
    {
      key: '/resource',
      icon: resourceIcon,
      label: 'Resource Lib',
      // permistion:['0','1'],
      children:[
        {
          key:'/resource/videolist',
          // icon: <UserOutlined />,
          label: 'Videos List',
          permistion:['0','1'],
        },
        {
          key:'/resource/freeexerciselist',
          label: 'Free Exercises',
          permistion:['0','1'],
        },
        {
          key:'/resource/newslist',
          label: 'News List',
          permistion:['0'],
        },
        {
          key:'/resource/Knowledgelist',
          label: 'Knowledge List'
        },
        {
          key:'/resource/resourcelist',
          label: 'Weekly Recource',
          permistion:['0','1'],
        },
      ]
    },
    {
      key: '/assessment',
      icon: assessmentIcon,
      label: 'Assessment',
      // permistion:['0','1'],
      children:[
        {
          key:'/assessment/comtest',
          label: 'Participants',
          permistion:['0'],
        },
        {
          key:'/assessment/videolist',
          // icon: <UserOutlined />,
          label: 'Videos List',
          permistion:['0','1'],
        }
      ]
    },
    {
      key: '/admin',
      icon: adminIcon,
      label: 'Administrator',
      // permistion:['0'],
      children:[
        {
            key:'/admin/adminlist',
            // icon: <UserOutlined />,
            label: 'Admin List',
            permistion:['0'],
        },
        {
          key:'/admin/videotest',
          // icon: <UserOutlined />,
          label: 'Video Test',
          permistion:['0'],
        },
        {
          key:'/ai/distance',
          // icon: <UserOutlined />,
          label: 'Distance Test',
          permistion:['0'],
        },
        {
          key:'/ai/assessment',
          // icon: <UserOutlined />,
          label: 'Assessment Test',
          permistion:['0'],
        },
        {
          key:'/ai/angletest',
          // icon: <UserOutlined />,
          label: 'Angle Array Test',
          permistion:['0'],
        }
      ]
    },
  ]
  useEffect(() => {
    let tempItems=[]
    items.forEach(item =>{
      let tmp=item.children.filter(child =>iwareUtils.hasPermission(child.permistion))
      item.children=tmp
      if(tmp.length > 0){tempItems.push(item)}
    })

    setauthorizedMenuItem(tempItems)
  },[])
    const collapsedFlag=useSelector((state)=>{
        return state.globalConfig.sidemenuFlag
    })
    const selectKeys=["/"+props.location.pathname.split("/")[1]+"/"+props.location.pathname.split("/")[2]]
    const openKeys=["/"+props.location.pathname.split("/")[1]]
    return (
        <Sider trigger={null} collapsible collapsed={collapsedFlag}>
            <div style={{display: 'flex', height:"100%",flexDirection: "column"}}>
                <div className="logo">ex-able</div>
                <div style={{flex:1,overflow:"auto"}}>
                    <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={selectKeys}
                    defaultOpenKeys={openKeys}
                    items={authorizedMenuItem}
                    onClick={(evt)=>{
                        console.log(evt)
                        props.history.push(evt.key)
                    }}
                    > 
                    </Menu>
                </div>
            </div>
            
        </Sider>
    )
}
export default withRouter(SideMenu)