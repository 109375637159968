import Countdown from '../../components/Countdown';
import React,{useEffect, useState,useRef} from 'react'
import { Space, Table, Button, Modal, message  } from 'antd';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {
  Backdrop,
  Stack,
  Typography
} from '@material-ui/core'

import MyTimer from './MyTimer';
export default function ComponentTest() {
  const audioRef = useRef(null)
  const [showCountdown,setShowCountdown]=useState(false)
  const [showTimer,setShowTimer]=useState(false)
  const handlePlay=()=>{
    console.log(audioRef)
    audioRef.current.play()
  }
  const handleCountdown=()=>{
    console.log("first",showCountdown)
    setShowCountdown(true)
    console.log("first",showCountdown)
  }
  
  const onCountdownComplete=()=>{
    setShowCountdown(false)
  }
  const renderTime = ({ remainingTime }) => {

    return (
        <div>
            <div style={{ fontSize: "80px" }}>{remainingTime}</div>
        </div>
    )
  }
  // useEffect(() => {
  //   if (!showCountdown) {
  //     return;
  //   }
  //   // Force a re-render after `showCountdown` is set to false
  //   const timeout = setTimeout(() => {
  //     setShowCountdown(false);
  //   }, 0);
  //   if (!showCountdown) {
  //     clearTimeout(timeout);
  //   }
  //   return () => clearTimeout(timeout);
  // }, [showCountdown]);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 5);

  return(
    <div>
      <div>
      <MyTimer expiryTimestamp={time} autoStart={false}/>
      </div>
      <audio ref={audioRef} preload="none" src="../../12374.mp3" >
        </audio>
        <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={showCountdown}
    // invisible={true}
    >
      <div>
        <CountdownCircleTimer
          isPlaying={showCountdown}
          // key={key}
          duration={500}
          size="300"
          colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
          onComplete={onCountdownComplete}
      >
          {renderTime}

        </CountdownCircleTimer>
      </div>
    </Backdrop>
        <Button type="primary" onClick={()=>handlePlay()} style={{marginBottom:'10px',marginRight:'20px'}}>Play Sound</Button>
        <Button type="primary" onClick={()=>handleCountdown()} style={{marginBottom:'10px',marginRight:'20px'}}>Countdown</Button>
        
    </div>
  )
}