import React from 'react'
import ReactPlayer from 'react-player'
import { Modal, message } from 'antd'
import config from '../../config'
import './video.css'
import {
    CloseCircleOutlined,
    ExclamationCircleOutlined
  } from '@ant-design/icons'

export default function VideoPlayer(props) {
    const handleRemoveVideo=(svid)=>{
        // message.error(svid)
        props.onRemove(svid)
    }
    const confirm=(svid) =>{
        Modal.confirm({
            title: 'Remove Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to remove this video?',
            okText: 'Yes',
            cancelText: 'No',
            onOk(){
                handleRemoveVideo(svid)
            }
          });
      }
  return (
    // <div >
    //     <div className='videoshow'>
    //         <div className="videoshowv"><ReactPlayer controls url={`${config.videoUrl}/${props.videofilename}`} height="100%" width="100%" /></div>
    //         <div className="videoshowi"><CloseCircleOutlined title='Remove this video' onClick={()=>confirm(props.svid)} /></div>
    //     </div>
    //     <div className='videoshowt'>{props.videotitle}</div>
    // </div>
    <div className="videosbox">
    <div className="videostyle" >
        <div>
        {/* <img className="leftvideo" src={`${config.picUrl}/${props.video.picname}`} /> */}
        <img className="leftvideo" src={props.video.picurl} />
        </div>
        <div title={props.video.title}>
        <div  className="righttext">{props.video.title}</div>
        <div  className="righttext">{props.video.repetitions} reps</div>
        </div>
    </div>
    <div className="videoshowi"><CloseCircleOutlined title='Remove this video' onClick={()=>confirm(props.video.id)} /></div>
    </div>
  )
}
