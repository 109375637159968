import axios from 'axios';
import config from '../config.js';

export default{
  async getAVideoList(){
   
    const jwt = localStorage.getItem('token');
 
    const options = {
        url: `${config.getAVideoListUrl}`,
        method: 'GET',
        headers: {
         Authorization: jwt
        },
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
  },

  async saveVideoInfo(videoinfo){
   
    const jwt = localStorage.getItem('token');
    const options = {
        url: `${config.videoAUploadUrl}`,
        method: 'POST',
        headers: {
          Authorization: jwt
        },
        data: {
					...videoinfo
				},
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
	},

  async getVideoInfoById(id){
   
    const jwt = localStorage.getItem('token');
 
    const options = {
        url: `${config.getAVideoListUrl}/${id}`,
        method: 'GET',
        headers: {
         Authorization: jwt
        },
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
  },
  
  async getVideoMulLangById(vid){
   
    const jwt = localStorage.getItem('token');
 
    const options = {
        url: `${config.aVideoMultilingualUrl}/${vid}`,
        method: 'GET',
        headers: {
         Authorization: jwt
        },
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
  },
  
  async getMulLangListById(vid){
    
    const jwt = localStorage.getItem('token');
 
    const options = {
        url: `${config.aVideoMultilingualUrl}/${vid}`,
        method: 'GET',
        headers: {
         Authorization: jwt
        },
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
  },

  async updateVideoInfoById(id,title,type,description,lang){
   
    const jwt = localStorage.getItem('token');
    const options = {
        url: `${config.getAVideoListUrl}/${id}`,
        method: 'POST',
        headers: {
        Authorization: jwt
        },
        data: {
          title,
          type,
          description,
          lang
        },
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
  },
  async addMultilingual(langid,vid,lang,title,description){
   
    const jwt = localStorage.getItem('token');
    const options = {
        url: `${config.aVideoMultilingualUrl}`,
        method: 'POST',
        headers: {
        Authorization: jwt
        },
        data: {
          langid,
          vid,
          lang,
          title,
          description,
        },
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
  },

  async deleteVideoByID(id){
   
    const jwt = localStorage.getItem('token');
 
    const options = {
        url: `${config.deleteAVideoUrl}/${id}`,
        method: 'DELETE',
        headers: {
         Authorization: jwt
        },
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
  },
}