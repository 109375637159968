import React,{useEffect, useState,useRef} from 'react'
import { Space, Table, Button, Modal, message  } from 'antd';
import './videolist.css'

import AVideoUpload from '../../components/video/AVideoUpload';
import AVideoUpdate from '../../components/video/AVideoUpdate';
import AVideoMultilingual from '../../components/video/AVideoMultilingual';

import ReactPlayer from 'react-player'
import { useHistory, useParams } from 'react-router-dom'
import {EditOutlined,DeleteOutlined,ExclamationCircleOutlined} from '@ant-design/icons'
import {setcollapsed,setcurrentoprtitle} from '../../redux/MvpReducer'
import { useDispatch,useSelector } from 'react-redux';
import assessmentService from '../../services/assessmentService';

import parse from 'html-react-parser'


export default function AVideoList() {
    const [dataSource,setdataSource]=useState([])
    const [isModalOpen,setisModalOpen]=useState(false)
    const [isUploadModalOpen,setisUploadModalOpen]=useState(false)
    const [isUpdateModalOpen,setisUpdateModalOpen]=useState(false)
    const [isOtherLangModalOpen,setisOtherLangModalOpen]=useState(false)
    const [currentDescription,setcurrentDescription]=useState("")
    const [currentVideoID,setcurrentVideoID]=useState("")
    const uploadForm=useRef(null)
    const navigate = useHistory()
    const dispatch = useDispatch()
   
    const columns = [
        {
          title: 'Video Name',
        //   dataIndex: 'title',
        //   key: 'id',
          render: (item) => <a onClick={()=>{
            setisModalOpen(true)
            setcurrentDescription(parse(item.description))
        }} title="Show descriptions">{item.title}</a>,
        },
        {
          title: 'Assessment Type',
        //   dataIndex: 'title',
        //   key: 'id',
          render: (item) => {
            switch(item.type){
              case 1:
                return 'Time Up-and-Go'
              case 2:
                return '1-minute sit-to-stand'
              case 3:
                return 'SPPB 5 Sit-to-stand'
              case 4:
                return 'SPPB Gait speed'
              case 5:
                return 'SPPB Balance'
              case 6:
                return '6-minute walk test'
            }
          },
        },
        {
          title: 'Thumbnail',
          dataIndex: 'picurl',
        //   key: 'age',
          width:'140px',
          align: 'center',
          render: (picurl) => (
            <img style={{borderRadius:'8px'}} src={picurl} height="120px" width="120px" />
          ),
        },
        {
          title: 'Video',
          dataIndex: 'videourl',
        //   key: 'age',
          width:'330px',
          align: 'center',
          render: (videourl) => (
            <ReactPlayer controls url={videourl} height="150px" width="266px" />
          ),
        },
        
        {
          title: 'language',
          // dataIndex: 'langlist.langid',
          key: 'age',
          width:'150px',
          align: 'center',
          dataIndex:'lang'
        },
        
        {
          title: 'Action',
          key: 'action',
          align: 'center',
          render: (item) => (
            <Space size="middle" direction="vertical">
              {/* <Button size="small" onClick={()=>handleOtherLang(item.id)}>Other Languages</Button> */}
              <Button size="small" onClick={()=>handlePopupUpdate(item.id)}>Update</Button>
              <Button size="small" onClick={()=>confirm(item.id)} type="primary" danger>Delete</Button>
            </Space>
          ),
        },
      ];
      // const browseVideo=(id)=> {
      //   navigate.push(`/video/videolist/${id}`)
      // }
      const confirm=(vid) =>{
        Modal.confirm({
            title: 'Delete Confirm',
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure you want to delete this assessment video?`,
            okText: 'Yes',
            cancelText: 'No',
            onOk(){
                deleteMethod(vid)
            }
          });
      }
      const deleteMethod=async (vid)=>{
        
        let list=dataSource.filter(data=>data.id!==vid)
        setdataSource([...list])
        const res= await assessmentService.deleteVideoByID(vid)
        
        if(res.code===0){
          message.success('delete successfully.')
        }else{
          message.error('delete failed!')
        }
    }
    useEffect(()=>{
        dispatch(setcurrentoprtitle("Assessment Video List"))

        // audioRef.current.addEventListener('canplay', () => {
        //   console.log("first")
        //   audioRef.current.play();
        // });
        // audioRef.current.load()

    },[])
    useEffect(()=>{
      
      fetchData()
    },[])
    const fetchData=async () =>{
      const res= await assessmentService.getAVideoList()
      console.log(res)
      if(res.code===0){
        setdataSource(res.data)
      }else{
          message.error(res.message)
      }
    }
    const handleCancel=()=>{
        setisUploadModalOpen(false)
        uploadForm.current.setFieldsValue({title:"",fileList:[],type:"",description:"",imageUrl:"",repetitions:""})
    }
    const handleCancelUpdate=()=>{
      setisUpdateModalOpen(false)
      setisOtherLangModalOpen(false)
      
  }
    const handleCancelDescription=()=>{
      setisModalOpen(false)
      
  }
    
    const handlePopupUpload=()=>{
        setisUploadModalOpen(true)
    }
    const handlePopupUpdate=(id)=>{
        setcurrentVideoID(id)
        setisUpdateModalOpen(true)
    }
    const handleOtherLang=(id)=>{
        setcurrentVideoID(id)
        setisOtherLangModalOpen(true)
    }
    const handleRefresh=async ()=>{
      fetchData()
    }
  return (
    <div>
      
        <Modal title="Assessment Video Upload" open={isUploadModalOpen}  closable={false} 
            footer={null
                // [<Button type="primary" onClick={handleCancel}>Close</Button>,
                // <Button type="primary" onClick={handleUploadVideo}>Upload</Button>]
            }
            width="950px"
        >
          <div zIndex="9999">
            <AVideoUpload  handleCancel={handleCancel} handleRefresh={handleRefresh} ref={uploadForm}></AVideoUpload>
          </div>
        </Modal>
        <Modal title="Assessment Video Update" open={isUpdateModalOpen}  closable={false} 
            footer={null
                // [<Button type="primary" onClick={handleCancel}>Close</Button>,
                // <Button type="primary" onClick={handleUploadVideo}>Upload</Button>]
            }
            width="950px"
        >
            <AVideoUpdate handleCancel={handleCancelUpdate} videoID={currentVideoID} handleRefresh={handleRefresh} ></AVideoUpdate>
        </Modal>
        <Modal title="Assessment Multiligual Update" open={isOtherLangModalOpen}  closable={false} 
            footer={null
                // [<Button type="primary" onClick={handleCancel}>Close</Button>,
                // <Button type="primary" onClick={handleUploadVideo}>Upload</Button>]
            }
            width="950px"
        >
            <AVideoMultilingual handleCancel={handleCancelUpdate} videoID={currentVideoID} handleRefresh={handleRefresh} ></AVideoMultilingual>
        </Modal>
        
        <Button type="primary" onClick={handlePopupUpload} style={{marginBottom:'10px'}}>Upload Video</Button>
        {/* <ReactPlayer url='https://www.youtube.com/watch?v=LBQvUXoTHp4' height="180px" width="320px" /> */}
    <Table columns={columns} dataSource={dataSource} scroll={{y:'calc(100vh - 310px)'}}
        pagination={{
            pageSize: 5,
            // hideOnSinglePage:true
        }}
        rowKey={(item)=>item.id}
    />
    <Modal title="Assessmenty Video Descriptions" open={isModalOpen}  closable={false} onCancel={handleCancel}
        footer={
            [<Button key="btnVD" type="primary" onClick={handleCancelDescription}>Close</Button>]
        }
    >
       {currentDescription}
       
    </Modal>
    </div>  
  )
}
