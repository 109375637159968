import { useState } from 'react'
import { Button, TextField, MenuItem, Skeleton } from '@material-ui/core';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import Webcam from "react-webcam";

export default function TestCameraDialog(props) {

    const [isCameraReady, setIsCameraReady] = useState(false);
    const onCameraReady = () => {
        setIsCameraReady(true);
    };
    const onDialogClosed = () => {
        setIsCameraReady(false);
    };
    return (
        <Dialog maxWidth='lg' onClose={onDialogClosed}
            open={props.open}>
            <DialogTitle>Camera Settings</DialogTitle>
            <DialogContent style={{width: 900}} dividers>
                {!isCameraReady && (
                    <Skeleton variant="rectangular" width={800} height={600}></Skeleton>
                )}
                <TextField
                    style={{width: 800}}
                    label="Selected Camera"
                    margin="normal"
                    name="selectedCameraDeviceId"
                    select
                    value={props.selectedDeviceId}
                    variant="outlined"
                >
                    {props.devices.map((device) => (
                        <MenuItem key={device.deviceId} value={device.deviceId}>
                            {device.label}
                        </MenuItem>
                    ))}
                </TextField>
                <Webcam
                    audio={false}
                    screenshotFormat="image/jpeg"
                    onUserMedia={onCameraReady}
                    videoConstraints={{
                        deviceId: props.selectedDeviceId,
                        width: 800,
                        height: 600
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" autoFocus onClick={props.onCancel}>
                Cancel
                </Button>
                <Button color="primary" onClick={props.onCancel}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}