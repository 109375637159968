import React, { useState,forwardRef,useRef, useEffect } from 'react';
import { PlusOutlined,UploadOutlined,LoadingOutlined } from '@ant-design/icons';
import {Form,Input,Button,Radio,Select,Cascader,Col,message,Checkbox,Upload,Row} from 'antd';
import {Editor} from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import assessmentService from '../../services/assessmentService';
import {getEditorStateFromValue,getDataFromEditor} from "../../util/common"


const AVideoUpdate=((props)=> {
    const vid=props.videoID
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState("");
    const [type, setType] = useState("");
    const [lang, setLang] = useState("");
    const [description, setDescription] = useState("")
    const [descriptionEditor, setDescriptionEditor] = useState(EditorState.createEmpty())
    const [imageUrl, setImageUrl] = useState();
    

    const videoFormRef = useRef(null);

    
    useEffect(()=>{
        
        async function fetchData() {
            const res= await assessmentService.getVideoInfoById(vid)
            if(res.code===0){
                const vinfo=res.data[0]
                let tType=''
                switch( vinfo.type){
                  case 1:
                    tType='Time Up-and-Go'
                    break
                  case 2:
                    tType='1-minute sit-to-stand'
                    break
                  case 3:
                    tType='SPPB 5 Sit-to-stand'
                    break
                  case 4:
                    tType='SPPB Gait speed'
                    break
                  case 5:
                    tType='SPPB Balance'
                    break
                  case 6:
                    tType='6-minute walk test'
                    break
                }
                videoFormRef.current.setFieldsValue({
                    title:vinfo.title,
                    type:tType,
                    lang:vinfo.lang
                })
                setType(vinfo.type)
                setLang(vinfo.lang)
                setImageUrl(vinfo.picurl)
                setDescriptionEditor(getEditorStateFromValue(vinfo.description))
                setDescription(vinfo.description)
            }else{
                message.error(res.message)
            }
        }
        fetchData();
        
    },[props])


    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div
          style={{
            marginTop: 8,
          }}
        >
          Upload
        </div>
      </div>
    );
    
    const hanleClose=()=> {

        setUploading(false)

        props.handleCancel()
    }
    
    const handleInput=(evt)=>{
        setTitle(evt.target.value)
        // console.log(text)
    }
    const handleUpdate = async () => {
        // console.log("----------------------")
        videoFormRef.current.validateFields().then(res=>{
            // const tmpType=res.type==='Exercise'?'1':'2'
            console.log(type)
            assessmentService.updateVideoInfoById(vid,res.title,type,description,lang).then(result=>{
              if(result.status===0){
                hanleClose()
                props.handleRefresh()
                message.success('Successfully updated')
              }else{
                message.error('Error updating')
              }
            })
            
        }).catch(err => {console.log(err)})
    }

  return (
    <div style={{zIndex:'9999'}}>
      <Form
        labelCol={{
          span: 3,
        }}
        wrapperCol={{
          span: 25,
        }}
        layout="horizontal"
        style={{
          maxWidth: 900,
          zIndex:9999,
        }}
        ref={videoFormRef}
      >
        <Form.Item label="Language"
        name="lang"
        rules={[{ required: true, message: 'Plese select language!' }]}
      >
        <Select
          onChange={(value) => setLang(value) }
        >
          <Select.Option value="en" key="1" >en</Select.Option>
          <Select.Option value="fr" key="2">fr</Select.Option>
        </Select>
      </Form.Item>
        <Form.Item label="Title"
            name="title"
            rules={[{ required: true, message: 'Plese input video title!' }]}
        >
          <Input onChange={handleInput}/>
        </Form.Item>
        <Form.Item label="Type"
        name="type"
        rules={[{ required: true, message: 'Plese select assessment type!' }]}
      >
        <Select
          onChange={(value) => setType(value) }
        >
          <Select.Option value="1" key="1" >Time Up-and-Go</Select.Option>
          <Select.Option value="2" key="2">1-minute sit-to-stand</Select.Option>
          <Select.Option value="3" key="3">SPPB 5 Sit-to-stand</Select.Option>
          <Select.Option value="4" key="4">SPPB Gait speed</Select.Option>
          <Select.Option value="5" key="5">SPPB Balance</Select.Option>
          <Select.Option value="6" key="6">6-minute walk test</Select.Option>
        </Select>
      </Form.Item>
        <Form.Item label="Description" name="description">
        <Editor
                        
            editorStyle={{border:"1px solid #ccc",height:'100px',zIndex:'9999'}}
            editorState={descriptionEditor}
            
            // toolbarClassName='tollbarClassName'
            // wrapperClassName='wrapperClassName'
            onEditorStateChange={(editorState)=>setDescriptionEditor(editorState)}
            onBlur={()=>{
                setDescription(getDataFromEditor(descriptionEditor))
            }}
        />
        </Form.Item>
        
        <Form.Item label="Thumbnail" 
            name="avatar"
        >
          
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  width: '120px',
                  height:'120px'
                }}
              />
            ) : (
              uploadButton
            )}
            
        </Form.Item>
        
        <Form.Item>
            <div style={{align: 'center'}}>

                <Button onClick={hanleClose} disabled={uploading}>Close</Button>
                <Button type="primary" 
                    onClick={handleUpdate}
                    loading={uploading}
                    style={{
                      marginLeft: 16,
                    }}
                >
                    {uploading ? 'Updating...' : 'Update & Close'}
                </Button>
            </div>
        </Form.Item>
      </Form>
    </div>
  
  )
})
export default AVideoUpdate
