const ApiHost = process.env.REACT_APP_IWARE_API_URL;
const api = "api";
const config = {
  defaultTimeout: 30000,
  s3bucketurl: "https://session-related.s3.amazonaws.com",
  accessKeyId: "AKIAY7NDNCR6WL7FG55V",
  secretAccessKey: "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",
  resetPasswordString: "Mvp-2022!",
  getS3ObjectUrl: `${ApiHost}/${api}/gets3objecturl`,
  baseUrl: `${ApiHost}`,
  videoUrl: "https://session-related.s3.amazonaws.com/videos",
  picUrl: "https://session-related.s3.amazonaws.com/pictures",
  getVideoListUrl: `${ApiHost}/${api}/getvideolist`,
  getAVideoListUrl: `${ApiHost}/${api}/getavideolist`,
  aVideoMultilingualUrl: `${ApiHost}/${api}/avideomultilingual`,
  getHasExercisePatientListUrl: `${ApiHost}/${api}/getexercisedpatientlist`,
  getExerciseVideoList: `${ApiHost}/${api}/getexercisevideolist`,
  getAdminListUrl: `${ApiHost}/${api}/getadminlist`,
  disableAdminByIDURL: `${ApiHost}/${api}/disableadmin`,
  adminInfoURL: `${ApiHost}/${api}/admininfo`,
  resetAdminPWDURL: `${ApiHost}/${api}/resetadminpassword`,
  changePWDURL: `${ApiHost}/${api}/changepwd`,
  getEWVideoListUrl: `${ApiHost}/${api}/getewvideolist`,
  deleteVideoUrl: `${ApiHost}/${api}/videolist`,
  deleteAVideoUrl: `${ApiHost}/${api}/avideolist`,
  authApi: `${ApiHost}/${api}/login`,
  videoStreamUrl: `${ApiHost}/${api}/playvideo/`,
  videoUploadUrl: `${ApiHost}/${api}/videoupload/`,
  videoAUploadUrl: `${ApiHost}/${api}/avideoupload/`,
  picUploadUrl: `${ApiHost}/${api}/picupload/`,
  uploadRecordingUrl: `${ApiHost}/${api}/patientvideoupload`,
  saveExerciseContentUrl: `${ApiHost}/${api}/exercisecontentupload`,
  getExerciseContentUrl: `${ApiHost}/${api}/getsessionexercisecontent/`,
  getSessionVideoListUrl: `${ApiHost}/${api}/getsessionvideolist/`,
  deleteSessionVideoUrl: `${ApiHost}/${api}/deltesessionvideo/`,
  addSessionVideoUrl: `${ApiHost}/${api}/addsessionvideo/`,
  addClinicLogUrl: `${ApiHost}/${api}/cliniclogsave`,
  getClinicLogUrl: `${ApiHost}/${api}/clinicloglist`,
  evalScoreUrl: `${ApiHost}/${api}/evalrecording`,
  angleTestUrl: `${ApiHost}/${api}/benchmarkanglearray/`,
};
export default config;
