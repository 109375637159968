import React,{useEffect,useState} from 'react'
import { Layout } from 'antd';

import nProgress from 'nprogress'
import 'nprogress/nprogress.css'

import SideMenu from '../../components/uilayout/SideMenu';
import TopHeader from '../../components/uilayout/TopHeader';
import ContentRouter from '../../components/uilayout/ContentRouter';

const { Header, Content,Footer } = Layout;


export default function Home() {
    const [collapsed, setCollapsed] = useState(false);
    let colorBgContainer="white"
    nProgress.start()
    useEffect(() => {
        nProgress.done()
    })
    return (
        <Layout>
            <SideMenu />
      
            <Layout className="site-layout">
                <TopHeader></TopHeader>
        
                <Content
                style={{
                    margin: '24px 16px',
                    padding: 10,
                    height: '100%',
                    // minHeight: 580,
                    background: colorBgContainer,
                    // border: '1px solid red',
                    // overflow:'auto'
                }}
                >
                    
                    <ContentRouter ></ContentRouter>
                </Content>
                <Footer
                style={{
                    textAlign: 'center',
                    paddingBottom:'20px',
                    paddingTop:'0'
                    // height:'50px'
                }}
                >
                ex-able ©2023 Created by ex-able.com
                </Footer>
      </Layout>
    </Layout>
    )
}
