import React,{useEffect, useState} from 'react'
import { Divider,Button,Modal, message } from 'antd'
// import ReactPlayer from 'react-player'
import config from '../../config'

import axios from 'axios'
import './video.css'
// import {
//     CloseCircleOutlined
//   } from '@ant-design/icons'
import VideoPlayer from './VideoPlayer'
import VideoSlect from './VideoSlect'

export default function VideoConfigured(props) {
    const [isUploadModalOpen,setisUploadModalOpen]=useState(false)
    const [videoList,setVideoList]=useState([])
    
    useEffect(()=>{
        setVideoList(props.videolist)
        console.log("new first")
    },[props])
    // const handleRemoveEV=(svid)=>{
    //     // console.log("first:",svid)
    //     props.onRemove(svid)
    // }
    const handleRemoveEV=(svid)=> {
        axios.delete(`${config.deleteSessionVideoUrl}${svid}`).then(res=>{
            // console.log(res)
            if(res.data.status===0){
                let tmpvlist=videoList.concat()
                tmpvlist=tmpvlist.filter(item=>item.id!==svid)
                setVideoList(tmpvlist)
                message.success('Deleted successfully')

            }
        })
    }
    const hanleAddV=(vidlist)=>{
        // console.log(vidlist)
        
        axios.post(`${config.addSessionVideoUrl}`,
            {
                params:{
                    sessionid:props.vsessionid,
                    vtype:props.vtype,
                    vidlist:vidlist,
                    opruser:localStorage.getItem("username")
                }
            }
        ).then(res=>{
            console.log(res)
            if(res.data.status===0){
                message.success('Added successfully')
                props.onAdd()
            }
        })
    }
    const handlePopupVSelect=()=>{
        // setisModalOpen(false)
        setisUploadModalOpen(true)
    }
    const handleCancel=()=>{
        // setisModalOpen(false)
        setisUploadModalOpen(false)
        // uploadForm.current.setFieldsValue({title:"",fileList:[],type:"",description:""})
        // setState({})
        // console.log(uploadForm)
    }
  return (
    <div className="videolistlayout">
        <Modal title={props.title+" Select"} open={isUploadModalOpen}  closable={false} 
            footer={null
                // [<Button type="primary" onClick={handleCancel}>Close</Button>,
                // <Button type="primary" onClick={handleUploadVideo}>Upload</Button>]
            }
            width="700px"
        >
            <VideoSlect handleCancel={handleCancel} vsessionid={props.vsessionid} vtype={props.vtype} onAdd={(vidlist)=>hanleAddV(vidlist)} timestamp={Date.now()}></VideoSlect>
            {/* <VideoUpload handleCancel={handleCancel} handleRefresh={handleRefresh} ref={uploadForm}></VideoUpload> */}
        </Modal>
        <div><Divider style={{fontWeight:"bold",marginBottom:"0"}}>{props.title}</Divider></div>
        <div ><Button style={{float: "right"}} type="primary" onClick={handlePopupVSelect}>Select Video</Button></div>
        {
        videoList.length===0 && 
        <div style={{fontSize:"16px",textAlign:"center",margin:"20px"}}>No video has been configured.<br></br>Please click the upper right button to configure.</div>
        }
        {
        videoList.length>0 &&
        
        <div className={props.displayclassname}>
            
            {    

                    videoList.map(item =>{
                        return <VideoPlayer video={item} key={item.id} onRemove={(svid)=>handleRemoveEV(svid)}/> 
                    })
                
            }
           
        </div>
        
        }
        
    </div>
  )
}
