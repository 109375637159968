import React,{useEffect, useState,useRef} from 'react'
import { Space, Table, Button, Modal, message,Badge,Dropdown  } from 'antd';
import './exercisevideolist.css'

import { useHistory, useParams } from 'react-router-dom'
import {setcollapsed,setcurrentoprtitle} from '../../redux/MvpReducer'
import { useDispatch,useSelector } from 'react-redux';
import videoService from '../../services/videoService';
import moment from 'moment'
import RetestVideo from '../../components/video/RetestVideo';

export default function ExerciseVideoList() {
  const [contentType,setContentType]=useState('')
  const [exerciseVID,setexerciseVID]=useState()

  const expandedRowRender =  (record) => {
    const columns = [
      {
        title: 'Session',
        dataIndex: 'session',
        key: 'session',
      },
      {
        title: 'Exercise Name',
        dataIndex: 'exercisename',
        key: 'exercisename',
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (date) => (
          moment(date).format('YYYY-MM-DD HH:mm:ss')
        ),
      },
      
      {
        title: 'Score',
        dataIndex: 'score',
        key: 'score',
        // render: () => <Badge status="success" text="Finished" />,
      },
      
      {
        title: 'Action',
        // dataIndex: 'key',
        // key: 'key',
        render: (item) => (
          <Space size="middle">
            <a onClick={()=>gotoRetest(item.key)}>Re-Test</a>
          </Space>
        ),
      },
    ];
    
  
    const gotoRetest=(id)=>{
      setContentType('retest')
      setexerciseVID(id)
      // navigate.push(`/patient/retestvideo/${id}`)
    }
    // getExerciseVideoList(record.key)
    // const data =[]//getExerciseVideoList(record.key)
    // console.log(pvdataSource)
    const data=pvdataSource.filter((item)=>{
      // console.log(item)  
      return item.patientid==record.key
    })
    
    
    return <Table columns={columns} dataSource={data} pagination={false} />
    
  };

  const columnsC = [
    {
      title: 'First Name',
      dataIndex: 'firstname',
      key: 'firstname',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastname',
      key: 'lastname',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phonenumber',
      key: 'phonenumber',
    },
    {
      title: 'Province',
      dataIndex: 'province',
      key: 'province',
    },
    {
      title: 'Birth Date',
      dataIndex: 'birthdate',
      key: 'birthdate',
    },
    // {
    //   title: 'Action',
    //   key: 'operation',
    //   render: () => <a>Publish</a>,
    // },
  ];

  const [pdataSource,setpdataSource]=useState([])
  const [pvdataSource,setpvdataSource]=useState([])

    const [dataSource,setdataSource]=useState([])
    const [isModalOpen,setisModalOpen]=useState(false)
    // const [isUploadModalOpen,setisUploadModalOpen]=useState(false)
    const [isUpdateModalOpen,setisUpdateModalOpen]=useState(false)
    const [currentDescription,setcurrentDescription]=useState("")
    const [currentVideoID,setcurrentVideoID]=useState("")
    const uploadForm=useRef(null)
    const navigate = useHistory()
    const dispatch = useDispatch()
    
      
    useEffect(()=>{
        dispatch(setcurrentoprtitle("Exercise Video List"))
    },[])
    useEffect(()=>{
      
      getExercisedPatientList()
      getExerciseVideoList()
    },[])
    const getExercisedPatientList=async () =>{
      const res= await videoService.getHasExercisePatientList()
      if(res.code===0){
        const data = [];
        for (let i = 0; i < res.data.length; ++i) {
          data.push({
            key: res.data[i].id,
            firstname: res.data[i].firstname,
            lastname: res.data[i].lastname,
            email: res.data[i].email,
            phonenumber: res.data[i].phonenumber,
            province: res.data[i].province,
            birthdate: res.data[i].birthdate,  
          });
        }
        setpdataSource(data)
      }else{
          message.error(res.message)
      }
    }
    const getExerciseVideoList=async () =>{
      // console.log(pid)
      const res= await videoService.getExerciseVideoList()
      if(res.code===0){
        const data = [];
        for (let i = 0; i < res.data.length; ++i) {
          data.push({
            key: res.data[i].id,
            session:res.data[i].sessionid,
            date: res.data[i].uploadtime,
            exercisename: res.data[i].exercisename,
            score: res.data[i].score,
            patientid:res.data[i].patientid,
            pvideourl:res.data[i].pvideourl
          })
        }
        setpvdataSource(data)
      }else{
          message.error(res.message)
      }
    }
    useEffect(()=>{
      
      fetchData()
    },[])
    const fetchData=async () =>{
      const res= await videoService.getVideoList()
      if(res.code===0){
        setdataSource(res.data)
      }else{
          message.error(res.message)
      }
    }
    const handleCancel=()=>{
        // setisUploadModalOpen(false)
        uploadForm.current.setFieldsValue({title:"",fileList:[],type:"",description:"",imageUrl:"",repetitions:""})
    }
   
    const handleCancelDescription=()=>{
      setisModalOpen(false)
      
  }
    // const handlePopupUpload=()=>{
    //     setisUploadModalOpen(true)
    // }
    

    if(contentType===''){

   
  return (
    <div style={{ height: '100%', overflow: 'auto' }}>
      
    <Table
        columns={columnsC}
        expandable={{
          expandedRowRender,
          // defaultExpandedRowKeys: ['0'],
        }}
        // expandedRowRender={(record) => (
        //   <p style={{ margin: 0 }}>{record.test}</p>
        // )}
        dataSource={pdataSource}
        size="small"
      />
    <Modal title="Video Descriptions" open={isModalOpen}  closable={false} onCancel={handleCancel}
        footer={
            [<Button key="btnVD" type="primary" onClick={handleCancelDescription}>Close</Button>]
        }
    >
       {currentDescription}
    </Modal>
    </div>  
  )
}else{
  const handleCancelUpdate=()=>{
    setContentType('')
    
  }
  return (
  <div>
    {/* <div className="toplink">
      <a onClick={()=>setContentType('')}>Go Back to Video List</a>
    </div> */}
    <div className="videocontent">
      <RetestVideo handleCancel={handleCancelUpdate} id={exerciseVID} ref={uploadForm}/>
    </div>
  </div>
  )
}
}
