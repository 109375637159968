import './assessment.css'

export default function Countdown(props) {

  return(
    <div className='repetition'>
      <div className='title'>Repetitions</div>
      <div className='time'>{props.times}</div>
    </div>
  )
}