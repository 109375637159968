import { useState } from 'react'
import { Button, DialogContentText } from '@material-ui/core';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';

export default function MessageDialog(props) {

    return (
        <Dialog onClose={props.onClose}
            open={props.open}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {props.choisTopic && (
                    <>
                        <Button color="primary" onClick={() => {
                            props.onYes(props.choisTopic);
                        }}>Yes</Button>
                        <Button color="secondary" onClick={props.onClose}>No</Button>
                    </>
                )}
                {!props.choisTopic && (
                    <Button color="primary" onClick={props.onClose}>Close</Button>
                )}
            </DialogActions>
        </Dialog>
    );
}