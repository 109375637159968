import logo from './logo.svg';
import './App.css';
import IndexRouter from './router/IndexRouter';


import Login from './views/login/Login';
function App() {
  

  return (
    //   
        <IndexRouter></IndexRouter>
    
  );
}

export default App;
