import React,{useEffect, useState} from 'react'
import { SkypeOutlined, AppleOutlined } from '@ant-design/icons';
import { Tabs } from 'antd'
import Session from '../../components/Session';

import {setcollapsed,setcurrentoprtitle} from '../../redux/MvpReducer'
import { useDispatch,useSelector } from 'react-redux';


export default function SessionList() {
    const [curSessionID,setcurSessionID]=useState(1)
    const dispatch = useDispatch()
    useEffect(()=>{
        localStorage.setItem("currentSessionID","1")
        dispatch(setcurrentoprtitle(" Session 1"))
    },[])
    
    
    const tabClick=(key) =>{
        dispatch(setcurrentoprtitle(" Session "+key.toString()))
        setcurSessionID(key)
        localStorage.setItem("currentSessionID",key.toString())
    }
  return (
    <Tabs
    defaultActiveKey="1"
    onTabClick={tabClick}
    // style={{height:"100%",border:"1px solid gray"}}
    items={[SkypeOutlined,SkypeOutlined,SkypeOutlined,SkypeOutlined,SkypeOutlined,SkypeOutlined,SkypeOutlined,SkypeOutlined,SkypeOutlined,SkypeOutlined, SkypeOutlined,SkypeOutlined].map((Icon, i) => {
      const id = String(i + 1);
      return {
        label: (
          <span style={{fontSize:"22px"}}>
            <Icon />
            {id}
          </span>
        ),
        key: id,
        children:(<div style={{maxHeight:"560px",display:"flex",overflow:"auto"}}>{curSessionID==id &&<Session style={{height:"300px"}} sessionid={curSessionID}></Session>}</div>),
      };
    })}
  />
  )
}
