import React,{useEffect, useState,useRef} from 'react'
import { Space, Table, Button, Modal, message  } from 'antd';

import config from '../../config';
import moment from 'moment'

import ClinicLogUpload from '../../components/clinic/ClinicUpload';

import ReactPlayer from 'react-player'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import {EditOutlined,DeleteOutlined,ExclamationCircleOutlined} from '@ant-design/icons'
import {setcollapsed,setcurrentoprtitle} from '../../redux/MvpReducer'
import { useDispatch,useSelector } from 'react-redux';

  // const data = [
  //   {
  //     key: '1',
  //     name: 'John Brown',
  //     age: 32,
  //     address: 'New York No. 1 Lake Park',
  //     tags: ['nice', 'developer'],
  //   },
  //   {
  //     key: '2',
  //     name: 'Jim Green',
  //     age: 42,
  //     address: 'London No. 1 Lake Park',
  //     tags: ['loser'],
  //   },
  //   {
  //     key: '3',
  //     name: 'Joe Black',
  //     age: 32,
  //     address: 'Sidney No. 1 Lake Park',
  //     tags: ['cool', 'teacher'],
  //   },
  // ];
export default function ClinicLogList() {
    const [dataSource,setdataSource]=useState([])
    const [isModalOpen,setisModalOpen]=useState(false)
    const [isUploadModalOpen,setisUploadModalOpen]=useState(false)
    const [currentDescription,setcurrentDescription]=useState("")
    const uploadForm=useRef(null)
    const navigate = useHistory()
    const dispatch = useDispatch()
    const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          width:'60px',
          align: 'center'
          // key: 'id',
        //   render: (item) => <a onClick={()=>{
        //     setisModalOpen(true)
        //     setcurrentDescription(item.description)
        // }} title="Show descriptions">{item.title}</a>,
        },
        {
          title: 'Patient',
          dataIndex: 'patientid',
          width:'190px',
        //   key: 'age',
          // width:'330px',
          // align: 'center',
          // render: (filename) => (
          //   <ReactPlayer controls url={config.videoUrl+'/'+filename} height="150px" width="266px" />
          // ),
        },
        {
          title: 'Session',
          dataIndex: 'sessionid',
          align: 'center',
          width:'100px'
        },
        {
          title: 'Visited?',
          dataIndex: 'visitflag',
          align: 'center',
          width:'100px',
          render: (visitflag) => (
            // item.type=="1"?'Exercise':'Warm up'
            visitflag===1?"Yes":"No"
          ),
        //   key: 'address',
        },
        {
          title: 'Visit Reason',
        //   key: 'tags',
          dataIndex: 'visitreason',
        },
        {
          title: 'Submit Time',
          dataIndex: 'oprtime',
          align: 'center',
          width:'190px',
          render: (oprtime) => (
            moment(oprtime).format('YYYY-MM-DD HH:mm:ss')
          ),
        },
      ];
      const browseVideo=(id)=> {
        navigate.push(`/video/videolist/${id}`)
      }
      const confirm=(item) =>{
        Modal.confirm({
            title: 'Delete Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to delete this item?',
            okText: 'Yes',
            cancelText: 'No',
            onOk(){
                deleteMethod(item)
            }
          });
      }
      const deleteMethod=(item)=>{
        
        let list=dataSource.filter(data=>data.id!==item.id)
        setdataSource([...list])
        axios.delete(`/api/videolist/${item.id}`).then(res=>{
          if(res.data.status===0){
            message.success('delete successfully.')
          }else{
            message.error('delete failed!')
          }
          // console.log(res)
        })
    }
    useEffect(()=>{
        dispatch(setcurrentoprtitle(" Clinic Log"))
    },[])
    useEffect(()=>{
        // console.log("first")
        axios.get(`${config.getClinicLogUrl}`).then(res=>{
            setdataSource(res.data.data)
            // console.log(dataSource)
        })
    },[])
    const handleCancel=()=>{
        // setisModalOpen(false)
        // 
        setisUploadModalOpen(false)
        uploadForm.current.setFieldsValue({visitreason:"",visitflag:""})
        // setState({})
        // console.log(uploadForm)
    }
    const handleCancelDescription=()=>{
      setisModalOpen(false)
      
  }
    const handlePopupUpload=()=>{
        // setisModalOpen(false)
        setisUploadModalOpen(true)
    }
    const handleRefresh=()=>{
        // this.setState({})
        console.log("first")
        axios.get(`${config.getClinicLogUrl}`).then(res=>{
            setdataSource(res.data.data)
            // console.log(dataSource)
        })
        // console.log("first")
    }
  return (
    <div>
        <Modal style={{textAlign:"center"}} title="Weekly clinic log" open={isUploadModalOpen}  closable={false} 
            footer={null
                // [<Button type="primary" onClick={handleCancel}>Close</Button>,
                // <Button type="primary" onClick={handleUploadVideo}>Upload</Button>]
            }
        >
            <ClinicLogUpload handleCancel={handleCancel} handleRefresh={handleRefresh} ref={uploadForm}></ClinicLogUpload>
        </Modal>
        <Button type="primary" onClick={handlePopupUpload}>Upload clinic log</Button>
        {/* <ReactPlayer url='https://www.youtube.com/watch?v=LBQvUXoTHp4' height="180px" width="320px" /> */}
    <Table columns={columns} dataSource={dataSource} scroll={{y:'calc(100vh - 310px)'}}
        pagination={{
            pageSize: 10,
            // hideOnSinglePage:true
        }}
        rowKey={(item)=>item.id}
    />
    
    </div>  
  )
}
