import { useState, useEffect, useRef, useCallback } from 'react'
import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-eva/close-fill';
import playIcon from '@iconify/icons-eva/play-circle-fill';
import stopPlayIcon from '@iconify/icons-eva/stop-circle-outline';
import recordIcon from '@iconify/icons-eva/recording-outline';
import configCamerIcon from '@iconify/icons-eva/camera-fill';
import {
    Backdrop,
    Button,
    Stack,
    Typography
} from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Webcam from "react-webcam";
import '@tensorflow/tfjs-backend-webgl';
import { createDetector, SupportedModels, movenet, calculators } from '@tensorflow-models/pose-detection';

import Page from '../../components/Page';
import TestCameraDialog from '../../components/TestCameraDialog';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';
import MessageDialog from '../../components/MessageDialog';
import videoService from '../../services/videoService';
import * as iwareUtils from "../../util/common"
import * as assessmentUtils from "../../util/assessment"
import { Progress, Space } from 'antd'
import Repetition from '../../components/assessment/repetition'
import ATimer from '../../components/assessment/timer'

// import { REACT_APP_IWARE_UTCOFFSET,REACT_APP_ASSESSMENT_WS } from '@/constants/iwareapi'

let detector = null;
const REACT_APP_ASSESSMENT_WS='ws://localhost:3388'

export default function AssessmentAI() {
  const ws=new WebSocket(REACT_APP_ASSESSMENT_WS)
  const timerRef = useRef(null);
  const stopTimer=()=>{
    console.log(timerRef)
    if(timerRef.current){
      console.log( timerRef.current.stopTimer())
    }
  }
  const [countFlag,setCountFlag]=useState(true)

    const { id } = useParams()
    // const videoPlayRef = useRef(null);
    const webCamRef = useRef(null);
    
    const instructionRecordingRef = useRef(null);
    const recordingRef = useRef(null);
    const webCamDivRef = useRef(null);
    const [score,setScore]=useState(0)
    const [isRecording, setIsRecording] = useState(false);
    const [cameraDevices, setCameraDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);
    const [showCountdown, setShowCountdown] = useState(false);
    const [recordButtonText, setRecordButtonText] = useState('STS');
    const [recordButtonIcon, setRecordButtonIcon] = useState(recordIcon);
    // const [videoButtonIcon, setVideoButtonIcon] = useState(playIcon);
    const [playing, setPlaying] = useState(false);
    // const [playButtonText, setPlayButtonText] = useState('Play Video');
    // const [videoUrl, setVideoUrl] = useState('');
    const [key, setKey] = useState(0);
    const [testCameraDialogOpen, setTestCameraDialogOpen] = useState(false);
    const [videoPlayProgress, setVideoPlayProgress] = useState(0);
    // const [detector, setDetector] = useState(null);
    const [posesList, setPosesList] = useState([]);
    const [messageDialogOpen, setMessageDialogOpen] = useState(false);
    const [messageDialogTitle, setMessageDialogTitle] = useState('');
    const [messageDialogMessage, setMessageDialogMessage] = useState('');
    const [messageDialogChoiceTopic, setMessageDialogChoiceTopic] = useState('');
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [cameraText, setCameraText] = useState('');

    const [actionPercent,setActionPercent] = useState(0)
    const [actiontimes,setactiontimes] = useState(0)
    const [trailcolor,setTrailcolor]=useState('lightgray')
    const [intervalID,setIntervalID] = useState(null)
    const format=(percent)=>(<span style={{color:'red',fontSize:'50px',fontWeight:'bold'}}>
    {actiontimes}
    
    </span>)

    //Assessment
    const [assessmentType,setAssessmentType] = useState(0)
    const timerIds=[]


const onTestCameraCancel = () => {
  setTestCameraDialogOpen(false);
};
const onRecord = (e) => {
  if(isRecording){
    clearInterval(intervalID)
  }
  if(playing){
    setPlaying(!playing)
  }
  setactiontimes(0)
  setIsRecording(!isRecording);
  setVideoPlayProgress(0);
  e.stopPropagation();
};
const onTestCameraClicked = (e) => {
  setTestCameraDialogOpen(true);
};
console.log("actiontimes",actiontimes)
// const onVideoPlayProgrss = () => {
//   if (videoPlayRef.current) {
//       if (playing) {
//           if (detector) {
//               var videoElement = document.getElementById('recording-camera');
//               detector.estimatePoses(videoElement).then((poses) => {
//                   poses.forEach(pose => {
//                       posesList.push({
//                           score: pose.score,
//                           keypoints: calculators.keypointsToNormalizedKeypoints(pose.keypoints, {
//                               height: videoElement.videoHeight,
//                               width: videoElement.videoWidth
//                           })
//                       });
//                   });
//               });
//           }
//       }
//       // const total = videoPlayRef.current.getDuration();
//       // const played = videoPlayRef.current.getCurrentTime();
//       // if (total && played) {
//       //     setVideoPlayProgress(played * 100 / total);
//       // }
//   }
// };
// console.log(id)
useEffect(() => {
  

  ws.onmessage=function(e){
    const data=JSON.parse(e.data)
    if(data.to===65){
      
      // setAssessmentFlag(true)
      // onRecord()
      // console.log('33345gi99999934343434343',Date.now(),data.assessmenttype)
      if(data.assessmenttype){
        sessionStorage.setItem('assessmenttype',data.assessmenttype)
        // if(isRecording){
          // newInterval(data.assessmenttype)
          document.getElementById("btnCloseVideo").click()
          setTimeout(() => {
            document.getElementById("btnCloseVideo").click()
        }, 2000);

          
          // onRecord()
        // newInterval(data.assessmenttype)
        // initalVideo()
        // setIsRecording(!isRecording)
        // // setIsRecording(!isRecording)
        // // instructionRecordingRef.current.removeEventListener('dataavailable', instructionCameraDataAvailable);
        // onCameraReady()
        //         setCameraText('Perfect! Please hold and the recording will start soon!');
        //         setTimeout(() => {
        //             ;
        //             // setCameraText('');
        //             // webCamDivRef.current.style.zIndex=-1;
        //             // webCamDivRef.current.style.position='fixed';
        //             // webCamDivRef.current.style.right='10px';
        //             // webCamDivRef.current.style.bottom='10px';
        //             // webCamDivRef.current.style.top=null;
        //             // webCamDivRef.current.style.left=null;
        //         }, 2000);
        // setAssessmentType(data.assessmenttype)
        console.log("___________________",assessmentType)
      }
      
      
      // onCameraReady()
      // setCameraText('')
    }

  }
},[])
const initalVideo=()=>{
  setactiontimes(0)
  // setIsRecording(!isRecording);
  setVideoPlayProgress(0);
}
const newInterval=(assessmenttype)=>{
  // if(isRecording){
  timerIds.forEach((timerId) => {
    clearInterval(timerId);
  })
  // }

  // let tmpF=false
  // const intervalid=setInterval(()=>{

  // const videoElement = document.getElementById('recording-camera')
  // detector.estimatePoses(videoElement).then((poses) => {
  //   console.log("first@@@@@@@@1",tmpF,assessmenttype)
  //   if(assessmentUtils.isAssessmentReady(poses,assessmenttype)){
  //     // setCountFlag(true)
  //     tmpF=true
  //   }
  //     console.log("first@@@@@@@@2",tmpF)
  //     if(assessmentUtils.isAssessmentDone(poses,assessmenttype) && tmpF){
  //     // if(assessmentUtils.isSTSReady(poses)){
  //       // console.log("first")
  //       setTrailcolor('#9ACD32')
  //       console.log("first",actiontimes)
  //       // increaseActionTimes()
  //       setactiontimes((prevC)=>prevC+1)
  //       // setCountFlag(false)
  //       tmpF=false
  //       console.log("first@@@@@@@@3",tmpF)
  //     }else{
  //       setTrailcolor('lightgray')
  //     }
  //     console.log(poses)
  //     // poses.forEach(pose => {
  //     //   console.log(pose)
  //     // })
  //   })
  // },500)
  // setIntervalID(intervalid)
  // timerIds.push(intervalid)
}

useEffect(() => {
  // var ws=new WebSocket(REACT_APP_ASSESSMENT_WS)

  ws.onopen=function() {

    ws.send(actiontimes)
  }
  
},[actiontimes])


useEffect(() => {

  setRecordButtonText(isRecording ? 'Stop Recording' : 'Record');
  setRecordButtonIcon(isRecording ? stopPlayIcon : recordIcon);
  if (isRecording) {
      // setShowCountdown(true);
      setKey(prevKey => prevKey + 1);
  }
  else {
      // setPlaying(false);
      if (recordingRef.current && recordingRef.current.state === 'recording') {
          recordingRef.current.stop();
      }
  }
}, [isRecording]);

// useEffect(() => {
//     setPlayButtonText(playing ? 'Stop Video' : "Play Video");
//     setVideoButtonIcon(playing ? stopPlayIcon : playIcon);
// }, [playing]);

useEffect(() => {
  navigator.mediaDevices.enumerateDevices().then((devices) => {
      // videoService.getVideoStream(id).then((url) => {
      //     setVideoUrl(url);
      // });
      const videoInputs = [];
      for (const device of devices) {
          if (device.kind === 'videoinput') {
              videoInputs.push({
                  deviceId: device.deviceId,
                  label: device.label
              });
          }
      }
      setCameraDevices(videoInputs);
      if (videoInputs.length > 0) {
          setSelectedDeviceId(videoInputs[0].deviceId);
      }
  });
}, []);
// const onPlay = (e) => {
//     const existingPlayingState = playing;
//     setPlaying(!playing);
//     if (existingPlayingState) {
//         videoPlayRef.current.seekTo(0);
//     }
//     e.stopPropagation();
// };
const handleDataAvailable = useCallback(
  ({ data }) => {
      if (data.size > 0) {
          setRecordedChunks((prev) => prev.concat(data));
      }
  },
  [setRecordedChunks]
);
const onCountdownComplete = () => {
  setShowCountdown(false);
  // videoPlayRef.current.seekTo(0);
  setPlaying(true);
  setRecordedChunks([]);
  recordingRef.current = new MediaRecorder(webCamRef.current.stream, {
      mimeType: "video/webm"
  });
  recordingRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
  );
  recordingRef.current.start();

  console.log("first",detector)

  let tmpF=false
  let assessmenttype=sessionStorage.getItem('assessmenttype')
  const intervalid=setInterval(()=>{
    
      const videoElement = document.getElementById('recording-camera')
        detector.estimatePoses(videoElement).then((poses) => {
          console.log("first@@@@@@@@1",tmpF,assessmenttype)
          if(assessmentUtils.isAssessmentReady(poses,assessmenttype)){
            // setCountFlag(true)
            tmpF=true
          }
            console.log("first@@@@@@@@2",tmpF)
            if(assessmentUtils.isAssessmentDone(poses,assessmenttype) && tmpF){
              if(assessmenttype==='1'){
                stopTimer()
              }
            // if(assessmentUtils.isSTSReady(poses)){
              // console.log("first")
              setTrailcolor('#9ACD32')
              console.log("first",actiontimes)
              // increaseActionTimes()
              setactiontimes((prevC)=>prevC+1)
              // setCountFlag(false)
              tmpF=false
              console.log("first@@@@@@@@3",tmpF)
            }else{
              setTrailcolor('lightgray')
            }
            console.log(poses)
            // poses.forEach(pose => {
            //   console.log(pose)
            // })
          })
    },500)
    setIntervalID(intervalid)
    timerIds.push(intervalid)
    // clearInterval(intervalID)

//   while (false) {
//     console.log("first2")
//     if (detector) {
//         var videoElement = document.getElementById('recording-camera');
//         detector.estimatePoses(videoElement).then((poses) => {
//             poses.forEach(pose => {
//               console.log(pose)
//               // const rightElbow=poses[0].keypoints[8]
//               // const rightShoulder=poses[0].keypoints[6]
//               // const rightWrist=poses[0].keypoints[10]
//               // const tmpRAngle=iwareUtils.angleBetweenVectors(rightElbow,rightShoulder,rightWrist)
//               // setCameraText(tmpRAngle)
//                 // posesList.push({
//                 //     score: pose.score,
//                 //     keypoints: calculators.keypointsToNormalizedKeypoints(pose.keypoints, {
//                 //         height: videoElement.videoHeight,
//                 //         width: videoElement.videoWidth
//                 //     })
//                 // });
//             });
//         });
//     }
// }
};



const increaseActionTimes=() => {

  // console.log("first",actiontimes)
  setactiontimes(actiontimes+1)
}
function calculateDistance(keypoints, index1, index2) {
const x1=keypoints[index1].x
const x2=keypoints[index2].x
const y1=keypoints[index1].y
const y2=keypoints[index2].y
const distance = Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
return distance;
}
    const instructionCameraDataAvailable = useCallback(
        () => {
            var videoElement = document.getElementById('recording-camera');
            
            detector.estimatePoses(videoElement).then((poses) => {
              console.log("first",poses[0].keypoints[8])
              const rightElbow=poses[0].keypoints[8]
              const rightShoulder=poses[0].keypoints[6]
              const rightWrist=poses[0].keypoints[10]
              const tmpRAngle=iwareUtils.angleBetweenVectors(rightElbow,rightShoulder,rightWrist)
              // if(tmpRAngle>=100){
              //   // console.log("first",tCount)
              //   tCount=tCount+1

              //   setactiontimes(tCount)
              //   // incrementCount()
              // }
              // // setCameraText('rightElbow:'+tmpRAngle)
              // setActionPercent(tmpRAngle)
              // // incrementCount()
              const leftElbow=poses[0].keypoints[7]
              const leftShoulder=poses[0].keypoints[5]
              const leftWrist=poses[0].keypoints[9]
              const tmpLAngle=iwareUtils.angleBetweenVectors(leftElbow,leftShoulder,leftWrist)


              const rightKnee=poses[0].keypoints[14]
              const rightHip=poses[0].keypoints[12]
              const rightAnkle=poses[0].keypoints[16]
              const tmpRKAngle=iwareUtils.angleBetweenVectors(rightKnee,rightHip,rightAnkle)
              
              const leftKnee=poses[0].keypoints[13]
              const leftHip=poses[0].keypoints[11]
              const leftAnkle=poses[0].keypoints[15]
              const tmpLKAngle=iwareUtils.angleBetweenVectors(leftKnee,leftHip,leftAnkle)

              
              const tmpLHAngle=iwareUtils.angleBetweenVectors(leftHip,leftShoulder,leftKnee)

              setCameraText('左腰:'+tmpLHAngle + '\r\nleftElbow:'+tmpLAngle + '\r\nrightKnee:'+tmpRKAngle+ '\r\n 左膝盖:'+tmpLKAngle+'\rn\n'+poses[0].keypoints[0].score)
              // // return;

                // if (!(poses && poses.length > 0)) {
                //     return;
                // }
                // let pose = poses[0];
                // for (const kp of pose.keypoints) {
                //     if (kp.score < 0.4) {
                //         return;
                //     }
                // }
                // return
                if(tmpRAngle<170 ){
                  return
                }
                // instructionRecordingRef.current.stop();
                instructionRecordingRef.current.removeEventListener('dataavailable', instructionCameraDataAvailable);
                setCameraText('Perfect! Please hold and the recording will start soon!');
                setTimeout(() => {
                    setShowCountdown(true);
                    // setCameraText('');
                    // webCamDivRef.current.style.zIndex=-1;
                    // webCamDivRef.current.style.position='fixed';
                    // webCamDivRef.current.style.right='10px';
                    // webCamDivRef.current.style.bottom='10px';
                    // webCamDivRef.current.style.top=null;
                    // webCamDivRef.current.style.left=null;
                }, 2000);

            });
        },
        [instructionRecordingRef]
    );

    const checkCamera = () => {

      setCameraText('Please move your entire body in camera');
      instructionRecordingRef.current = new MediaRecorder(webCamRef.current.stream, {
          mimeType: "video/webm"
      });
      instructionRecordingRef.current.addEventListener(
          "dataavailable",
          instructionCameraDataAvailable
      );
      instructionRecordingRef.current.start(500);
  };
  const onCameraReady = () => {
      createDetector(SupportedModels.MoveNet, {
          modelType: movenet.modelType.SINGLEPOSE_LIGHTNING
      }).then((res) => {
          detector = res;
          setPosesList([]);
          checkCamera();
      });
  };
  const onVideoEnded = () => {
      setPlaying(!playing)
      
      // if(isRecording){
          // evalRecording(id, posesList).then((res) => {
              setScore(98)
              setMessageDialogTitle('Recording Complete');
              setMessageDialogMessage(`Your score is ${score} out of 100! Do you want to upload your recording for your doctor to review?`);
              // setMessageDialogMessage(`Your score is ${Math.round(res.score * 100) / 100} out of 100! Do you want to upload your recording for your doctor to review?`);
              setMessageDialogChoiceTopic('uploadRecording');
              setMessageDialogOpen(true);
          // });
      // }
      setIsRecording(false);
      
  };
  const onMessageDialogYes = (topic) => {

      if (topic === 'uploadRecording') {
          // console.log("uploading")
          var reader = new FileReader();
          reader.readAsDataURL(new Blob(recordedChunks, {
              type: "video/webm"
          }));
          reader.onloadend = function () {
              var recordingContent = reader.result;
              videoService.uploadRecording(id, posesList, recordingContent,score);
          }
      }
  };

  const renderTime = ({ remainingTime }) => {

      return (
          <div>
              <div style={{ fontSize: "80px" }}>{remainingTime}</div>
          </div>
      );
  };

    return (
        <Page title="iWareHealth | Prescription Activity">
          {`${countFlag}`}
            
            <ATimer ref={timerRef} start={playing}/>
            <button onClick={stopTimer}>Stop</button>
            <Stack sx={{ height: '100%' }}>
                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Cardiac Rehabilitation Exercises
                    </Typography>
                    
                </Stack> */}
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={showCountdown}
                // invisible={true}
                >
                    <div>
                        <CountdownCircleTimer
                            isPlaying={showCountdown}
                            key={key}
                            duration={2}
                            size="300"
                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                            onComplete={onCountdownComplete}
                        >
                            {renderTime}
                        </CountdownCircleTimer>
                    </div>
                </Backdrop>
                <TestCameraDialog open={testCameraDialogOpen}
                    onCancel={onTestCameraCancel}
                    devices={cameraDevices}
                    selectedDeviceId={selectedDeviceId} />
                <MessageDialog
                    open={messageDialogOpen}
                    title={messageDialogTitle}
                    message={messageDialogMessage}
                    onClose={() => setMessageDialogOpen(false)}
                    onYes={(topic) => {
                        setMessageDialogOpen(false);
                        onMessageDialogYes(topic);
                    }}
                    choisTopic={messageDialogChoiceTopic}
                />
                <div style={{ flexGrow: 1 }}>
                    <Stack direction="row">
                        <Button variant="contained"
                            style={{ margin: 2 }}
                            startIcon={<Icon icon={configCamerIcon} />}
                            color="secondary"
                            onClick={onTestCameraClicked}
                            size="large"
                            disabled={isRecording}
                        >
                            Test Camera
                        </Button>
                        {/* <Button variant="contained"
                            style={{ margin: 2 }}
                            startIcon={<Icon icon={videoButtonIcon} />}
                            color="secondary"
                            size="large"
                            onClick={onPlay}
                            disabled={isRecording}
                        >
                            {playButtonText}
                        </Button> */}
                        <Button variant="contained"
                            startIcon={<Icon icon={recordButtonIcon} />}
                            color="primary"
                            style={{ margin: 2 }}
                            size="large"
                            onClick={onRecord}
                            id="btnCloseVideo"
                        >
                            {recordButtonText}
                        </Button>
                        <Button variant="contained"
                            startIcon={<Icon icon={recordButtonIcon} />}
                            color="primary"
                            style={{ margin: 2 }}
                            size="large"
                            onClick={increaseActionTimes}
                        >
                            add
                        </Button>
                        {/* <Button onClick={incrementCount}>Add</Button> */}
                        {isRecording && (
                            <div style={{ marginLeft: 10, marginTop: 5 }}>
                                <CircularProgressWithLabel value={videoPlayProgress} />
                            </div>
                        )}
                    </Stack >
                    <div style={{width: '100%', height: '100%',position: 'relative'}}>
                        {isRecording && (
                          // {1 && (
                            <div ref={webCamDivRef} style={{ position: 'absolute', left: 0, top: 0, zIndex: 1000 ,display:"flex"}}>
                                <Webcam id="recording-camera"
                                    audio={false}
                                    screenshotFormat="image/jpeg"
                                    onUserMedia={onCameraReady}
                                    videoConstraints={{
                                        deviceId: selectedDeviceId,
                                        width: 1000,
                                        height: 830
                                    }}
                                    ref={webCamRef}
                                />
                                <Typography align='center' variant='h1' color='orange' style={{ position: 'absolute', top: '5%', zIndex: 1001 }}>
                                    {cameraText}
                                </Typography>
                                <div>
                                <Repetition times={actiontimes}/>
                                  {/* {actiontimes}
                                  <Progress type="circle" percent={actionPercent} strokeWidth={25} trailColor={trailcolor} strokeColor={'green'} strokeLinecap="butt" format={format} /> */}
                                </div>
                            </div>
                        )}
                        
                    </div>
                </div>
            </Stack>
        </Page>
    );
}
