import * as iwareUtils from "./common"

// export const isSTSReady=(poses)=>{
//   let flag=false
  
//   if(poses[0].keypoints[8].score>0.4)
//   {
//     console.log(poses[0].keypoints[8].score)
//     flag=true
//   }
//   return flag
// }

export const isAssessmentReady=(poses,atype)=>{
  console.log(atype)
  switch(atype){
    case "1":
      return isTUGReady(poses)
    case "2":
      return isSTSReady(poses)
    default:
      return false
  }
}

export const isAssessmentDone=(poses,atype)=>{

  switch(atype){
    case "1":
      return isTUGDone(poses)
    case "2":
      return isSTSDone(poses)
    default:
      return false
  }
}

const isTUGReady=(poses)=>{
  let flag=false
  const lShoulder=poses[0].keypoints[5]
  const rShoulder=poses[0].keypoints[6]
  const rKnee=poses[0].keypoints[14]
  const lKnee=poses[0].keypoints[13]
  const rHip=poses[0].keypoints[12]
  const lHip=poses[0].keypoints[11]
  const rAnkle=poses[0].keypoints[16]
  const lAnkle=poses[0].keypoints[15]
  
  const lKAngle=iwareUtils.angleBetweenVectors(lKnee,lHip,lAnkle)
  const rKAngle=iwareUtils.angleBetweenVectors(rKnee,rHip,rAnkle)

  const lHAngle=iwareUtils.angleBetweenVectors(lHip,lShoulder,lKnee)
  const rHAngle=iwareUtils.angleBetweenVectors(rHip,rShoulder,rKnee)
  
  if((lKAngle>150 || rKAngle>150)&&(rHAngle>140||lHAngle>140))
  {
    flag=true
  }
  return flag
}

const isTUGDone=(poses)=>{
  let flag=false
  const lShoulder=poses[0].keypoints[5]
  const rShoulder=poses[0].keypoints[6]
  const rKnee=poses[0].keypoints[14]
  const lKnee=poses[0].keypoints[13]
  const rHip=poses[0].keypoints[12]
  const lHip=poses[0].keypoints[11]
  const rAnkle=poses[0].keypoints[16]
  const lAnkle=poses[0].keypoints[15]
  
  const lKAngle=iwareUtils.angleBetweenVectors(lKnee,lHip,lAnkle)
  const rKAngle=iwareUtils.angleBetweenVectors(rKnee,rHip,rAnkle)

  const lHAngle=iwareUtils.angleBetweenVectors(lHip,lShoulder,lKnee)
  const rHAngle=iwareUtils.angleBetweenVectors(rHip,rShoulder,rKnee)
  
  if((lKAngle<95 || rKAngle<95)&&(rHAngle<95||lHAngle<95))
  {
    flag=true
  }
  return flag
}

const isSTSReady=(poses)=>{
  let flag=false
  let angle=iwareUtils.angleBetweenVectors(poses[0].keypoints[7],poses[0].keypoints[5],poses[0].keypoints[9])
  console.log(angle)
  if(angle>100)
  {
    flag=true
  }
  return flag
}

const isSTSDone=(poses)=>{
  let flag=false
  let angle=iwareUtils.angleBetweenVectors(poses[0].keypoints[7],poses[0].keypoints[5],poses[0].keypoints[9])
  console.log(angle)
  if(angle<35)
  {
    flag=true
  }
  return flag
}



