import axios from 'axios';
import config from '../config.js';

export default{
    async evalRecording(videoId,poses) {
        const jwt = localStorage.getItem('token');
        const options = {
            url: `${config.evalScoreUrl}`,
            method: 'POST',
            headers: {
                Authorization: jwt
            },
            data: {
                videoId,
                poses
            },
            timeout: config.defaultTimeout
        };
        const res = await axios(options);
        return res.data;
    },

    async getVideoStream(id)  {
   
       // const jwt = localStorage.getItem('jwt');
       const options = {
           url: `${config.videoStreamUrl}${id}`,
           method: 'GET',
           responseType: 'blob',
           // headers: {
           //     'Authorization': jwt
           // },
           timeout: config.defaultTimeout
       };
       const res = await axios(options);
       return URL.createObjectURL(res.data);
   },
    
   
   async getVideoInfoById(id){
   
       const jwt = localStorage.getItem('token');
    
       const options = {
           url: `${config.getVideoListUrl}/${id}`,
           method: 'GET',
           headers: {
            Authorization: jwt
           },
           timeout: config.defaultTimeout
       };
       const res = await axios(options);
       return res.data;
   },
   
	 async deleteVideoByID(id){
   
       const jwt = localStorage.getItem('token');
    
       const options = {
           url: `${config.deleteVideoUrl}/${id}`,
           method: 'DELETE',
           headers: {
            Authorization: jwt
           },
           timeout: config.defaultTimeout
       };
       const res = await axios(options);
       return res.data;
   },
   
	 async getVideoList(){
   
       const jwt = localStorage.getItem('token');
    
       const options = {
           url: `${config.getVideoListUrl}`,
           method: 'GET',
           headers: {
            Authorization: jwt
           },
           timeout: config.defaultTimeout
       };
       const res = await axios(options);
       return res.data;
   },
	 
   async getHasExercisePatientList(){
   
       const jwt = localStorage.getItem('token');
    
       const options = {
           url: `${config.getHasExercisePatientListUrl}`,
           method: 'GET',
           headers: {
            Authorization: jwt
           },
           timeout: config.defaultTimeout
       };
       const res = await axios(options);
       return res.data;
   },
   
   async getExerciseVideoList(){
   
       const jwt = localStorage.getItem('token');
    
       const options = {
           url: `${config.getExerciseVideoList}`,
           method: 'GET',
           headers: {
            Authorization: jwt
           },
           timeout: config.defaultTimeout
       };
       const res = await axios(options);
       return res.data;
   },
   async getExerciseVideoByID(vid){
   
    const jwt = localStorage.getItem('token');
 
    const options = {
        url: `${config.getExerciseVideoList}/${vid}`,
        method: 'GET',
        headers: {
         Authorization: jwt
        },
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
},

    async updateVideoInfoById(id,title,type,repetitions,description){
   
        const jwt = localStorage.getItem('token');
        const options = {
            url: `${config.getVideoListUrl}/${id}`,
            method: 'POST',
            headers: {
            Authorization: jwt
            },
            data: {
                        title,
                        type,
                        repetitions,
                        description,
                    },
            timeout: config.defaultTimeout
        };
        const res = await axios(options);
        return res.data;
    },
	async saveVideoInfo(videoinfo){
   
    const jwt = localStorage.getItem('token');
    const options = {
        url: `${config.videoUploadUrl}`,
        method: 'POST',
        headers: {
          Authorization: jwt
        },
        data: {
					...videoinfo
				},
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
	},
   
   async uploadRecording(videoId, poses, recording,score){
       // console.log(videoId)
       console.log(poses)
       console.log(recording.length)
       const userid=localStorage.getItem('userid')
       const sessionid=localStorage.getItem('sessionid')||1
       const kinid=localStorage.getItem('kinid')||1
       // const jwt = localStorage.getItem('jwt');
       const options = {
           url: `${config.uploadRecordingUrl}`,
           method: 'POST',
           data: {
               videoId,
               poses,
               recording,
               userid,
               sessionid,
               kinid,
               score
           },
           
           timeout: config.defaultTimeout
       };
       const res = await axios(options);
       console.log(res)
       return res.data;
   }
}
    