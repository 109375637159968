import axios from 'axios';
import config from '../config.js';

export default{
  async getAdminList(){
   
    const jwt = localStorage.getItem('token');
 
    const options = {
        url: `${config.getAdminListUrl}`,
        method: 'GET',
        headers: {
         Authorization: jwt
        },
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
  }, 
  async disableAdminByID(id,isactive){
   
    const jwt = localStorage.getItem('token');
 
    const options = {
        url: `${config.disableAdminByIDURL}/${id}`,
        method: 'POST',
        headers: {
         Authorization: jwt
        },
        data: {
          isactive,
        },
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
  }, 
  async deleteAdminByID(id){
   
    const jwt = localStorage.getItem('token');
 
    const options = {
        url: `${config.adminInfoURL}/${id}`,
        method: 'DELETE',
        headers: {
         Authorization: jwt
        },
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
  }, 
  async getAdminByID(id){
   
    const jwt = localStorage.getItem('token');
 
    const options = {
        url: `${config.adminInfoURL}/${id}`,
        method: 'GET',
        headers: {
         Authorization: jwt
        },
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
  }, 
  async resetAdminPWDByID(id){
   
    const jwt = localStorage.getItem('token');
 
    const options = {
        url: `${config.resetAdminPWDURL}/${id}`,
        method: 'POST',
        headers: {
         Authorization: jwt
        },
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
  }, 
  async updateAdminByID(id,userinfo){
   
    const jwt = localStorage.getItem('token');
 
    const options = {
        url: `${config.adminInfoURL}/${id}`,
        method: 'POST',
        headers: {
         Authorization: jwt
        },
        data:{
          ...userinfo
        },
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
  }, 
  
  async changePWD(id,pwdinfo){
   
    const jwt = localStorage.getItem('token');
 
    const options = {
        url: `${config.changePWDURL}/${id}`,
        method: 'POST',
        headers: {
         Authorization: jwt
        },
        data:{
          ...pwdinfo
        },
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
  }, 

  async addUser(userinfo){
   console.log(userinfo)
    const jwt = localStorage.getItem('token');
    const options = {
        url: `${config.adminInfoURL}`,
        method: 'POST',
        headers: {
          Authorization: jwt
        },
        data: {
					...userinfo
				},
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data;
	},
}
    