import { useState, useEffect, useRef, useCallback } from 'react'
import testService from '../../services/testService'
import { Space, Table, Button, Modal, message,Switch  } from 'antd'

export default function AssessmentAI() {

  const doTest=async () =>{
    const res= await testService.doTest(90)
    console.log(res)
    if(res.code===0){
      console.log(res.data)
    }else{
        message.error(res.message)
    }
  }

  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);

  
  const handleScan = async () => {
    try {
      const newDevices = await navigator.bluetooth.requestDevice({
        acceptAllDevices: true,
        // optionalServices: ['heart_rate']
      });
      console.log(newDevices)
      const bserver=await newDevices.gatt.connect()
      console.log(bserver)
      // navigator.bluetooth.requestDevice({filters:[{name:['liu bo的AirPods']}]}).then(device => {
      //   console.log(device)
      //   return device.gatt.connect()
      // }).then((server)=>{
      //   console.log(server)
      // }).catch((error)=>{
      //   console.log(error)
      // })

      // 扫描设备
      // const newDevices = await navigator.bluetooth.requestDevice({
      //   acceptAllDevices: true,
      //   // optionalServices: ['heart_rate']
      // });
      // // await newDevices.gatt.connect();
      // console.log(newDevices)
      // // const newDevices = await navigator.bluetooth.getDevices();

      // // 更新设备列表
      // setDevices(newDevices);
      // navigator.bluetooth.requestDevice({acceptAllDevices: true})
      // .then(device => {
      //   // 成功连接到设备
      //   return device.gatt.connect();
      // })
      // .then(server => {
      //   // 成功获取GATT服务器
      //   // 在这里执行您的操作
      //   console.log("chenggongle")
      // })
      // .catch(error => {
      //   // 处理错误
      // });
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelectDevice = async (device) => {
    try {
      // 连接设备
      await device.gatt.connect();
      
      // 获取服务
      const service = await device.gatt.getPrimaryService('heart_rate');

      // 完成配对
      await service.getCharacteristic('heart_rate_measurement')
        .startNotifications()
        .catch(error => console.log(error));
      
      // 更新所选设备
      setSelectedDevice(device);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <button onClick={doTest}>Test Angle</button>

      <button onClick={handleScan}>扫描设备</button>
      {/* <ul>
        {devices.map(device => (
          <li key={device.id} onClick={() => handleSelectDevice(device)}>
            {device.name}
          </li>
        ))}
      </ul> */}
      {selectedDevice && (
        <p>设备已配对: {selectedDevice.name}</p>
      )}
    </div>
  )
}