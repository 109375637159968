import React, { useState,forwardRef,useRef,useEffect } from 'react';
import { PlusOutlined,UploadOutlined } from '@ant-design/icons';
import {
    Table,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  message,
  Checkbox,
  Upload,
} from 'antd';
import axios from 'axios';
import config from '../../config'
import ReactPlayer from 'react-player'

const VideoSelect=forwardRef((props,ref)=> {
    // console.log(props)
    const [dataSource,setdataSource]=useState([])
    const [oprFlag,setoprFlag] =useState(false)

    const columns = [
        {
          title: 'Title',
          dataIndex: 'title',
        },
        {
          title: 'Type',
          dataIndex: 'type',
          render: (type) => (
            type===1?"Exercise":type===2?"Warm up":"Assessment"
          ),
        //   width:"90px"
        },
        {
          title: 'Thumbnail',
          dataIndex: 'picurl',
          align: 'center',
          width: '130px',
        
          render: (picurl) => (
            <img src={picurl} height="100px" width="100px" style={{borderRadius:'8px'}} />
          ),
        },
        {
          title: 'Video',
          dataIndex: 'videourl',
          align: 'center',
          width: '200px',
          render: (videourl) => (
            <ReactPlayer controls url={videourl} height="80px" width="150px" />
          ),
        },
      ];
      const data = [];
      for (let i = 0; i < 46; i++) {
        data.push({
          key: i,
          name: `Edward King ${i}`,
          age: 32,
          address: `London, Park Lane no. ${i}`,
        });
      }
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    // const start = () => {
    //     setLoading(true);
    //     // ajax request after empty completing
    //     setTimeout(() => {
    //     setSelectedRowKeys([]);
    //     setLoading(false);
    //     }, 1000);
    // };
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0
    const handleAdd=()=>{
        props.onAdd(selectedRowKeys)
        // removeVideoFromList(selectedRowKeys)
        // setoprFlag(true)
        props.handleCancel()
        setSelectedRowKeys([])
    }
    const removeVideoFromList = (selectedRowKeys)=>{
        let tmpList=dataSource.concat()
        selectedRowKeys.forEach((item)=>{
            let detailData = tmpList.find((v) => { return v.id == item; })
            let tmpindex=tmpList.indexOf(detailData)
            tmpList.splice(tmpindex,1)
        })

        setdataSource(tmpList)
    }
    const hanleClose=()=> {
        // console.log(fileList)
        // console.log(props)
        // setFileList([]);
        // console.log(fileList)
        // ref.fileForm.resetFields()
        // console.log(title)
        props.handleCancel()
        // ref.current.setisUploadModalOpen(false)
    }
    useEffect(()=>{
        console.log("goto")
        axios.get(`${config.getEWVideoListUrl}`,
            {params:{
                sessionid:props.vsessionid,
                type:props.vtype
            }}
        ).then(res=>{
            console.log(res)
            setdataSource(res.data.data)
            // console.log(dataSource)
        })
    },[props])
  return (
    <div>
      <div
        style={{
          marginBottom: 16,
        }}
      >
        <Button onClick={hanleClose} style={{marginRight:"20px"}}>Close</Button>
        <Button type="primary" onClick={handleAdd} disabled={!hasSelected} loading={loading}>
          Add
        </Button>
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
        </span>
      </div>
      <Table rowSelection={rowSelection} columns={columns} dataSource={dataSource} pagination={{pageSize:4}}
        rowKey={(item)=>item.id} 
      />
    </div>
  )
})
export default VideoSelect
