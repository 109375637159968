import React, { useState, useEffect,forwardRef,useImperativeHandle } from 'react';
import './assessment.css'

const Timer=forwardRef((props,ref)=> {
  const [seconds, setSeconds] = useState(0);
  const [duration, setDuration] = useState(0);
  const [timer, setTimer] = useState();

  useEffect(() => {
    if(props.start){

      const intervalId = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
      setTimer(intervalId)
      return () => clearInterval(intervalId);
    }else{
      setSeconds(0)
    }
  }, [props.start]);
  const stopTimer=()=>{
    setDuration(seconds)
    clearInterval(timer)
    return seconds
  }
  useImperativeHandle(ref, () => ({
    stopTimer,
  }));
  return (
    <div className="timer">
      <div className="title">Timer</div>
      <div className="time">{seconds}</div>
      <h2>耗时 {duration} 秒</h2>
      <button onClick={() =>stopTimer()}>stop</button>
    </div>
  );
})

export default Timer;
