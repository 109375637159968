import React, { useState, forwardRef, useRef, useEffect } from 'react';
import { Form, Input, Button, Select, message,  } from 'antd';

import adminService from '../../services/adminService'
import config from '../../config';

const ChangePWD=forwardRef((props,ref)=> {
  
  const [oldpwd, setOldPwd] = useState("");
  const [newpwd, setNewPwd] = useState("");
  const [cpwd, setCPwd] = useState("");

  const curRef=useRef(null)
  // useEffect(() => {
  //   adminService.getAdminByID(props.adminID).then(res=>{
  //     if(res.status===0){
  //       const adminInfo=res.data[0]
  //       curRef.current.setFieldsValue({
  //         username:adminInfo.username,
  //         // repetitions:vinfo.repetitions,
  //         type:adminInfo.type===1?'Administrator':'Kinesiologist'
  //     })
  //     }
  //     console.log(res)
  //   })
  //   console.log(props)
  // },[props])
  const hanleClose = () => {
  
    props.handleCancel()
  }
  
  const handleInputO = (evt) => {
    setOldPwd(evt.target.value)
  }
  const handleInputN = (evt) => {
    setNewPwd(evt.target.value)
  }
  const handleInputC = (evt) => {
    setCPwd(evt.target.value)
  }

  
  const handleAdd = async () => {
    console.log("first")
    ref.current.validateFields().then(res => {
      console.log(res)
      updateAdmin(localStorage.getItem("userid"),res)
    }).catch(err => { console.log(err) })

  };
  const updateAdmin=async (adminid,userinfo)=>{
    const res= await adminService.changePWD(adminid,userinfo)
    if(res.status ===1){
      message.error(res.message)
    }else{
      message.success('Updated successfully, please re-login.')
      props.handleCancel()
      props.handleLogout()
    }
  }
  const validatePassword=(rule,value,callback)=>{
    const pwdRegex=/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\-!@#$%^&*])[A-Za-z\d\-!@#$%^&*]{8,}$/


    if(!pwdRegex.test(value)){
      callback(`The password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and at least one of these 9 special characters('-!@#$%^&*').`)
    }else{
      callback()
    }
  }
  const validateCPassword=(rule,value,callback)=>{
    if(newpwd!=cpwd){
      callback('The two entered passwords are inconsistent, please re-enter')
    }else{
      callback()
    }
  }
  return (
    <Form
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 17,
      }}
      layout="horizontal"
      style={{
        maxWidth: 900,
        
      }}
      ref={ref}
    >

      <Form.Item label="Old Password"
        name="oldpassword"
        
        rules={[{ required: true, message: 'Plese input the old password!' }]}
      >
        <Input.Password onChange={handleInputO} />
      </Form.Item>
      <Form.Item label="New Password"
        name="newpassword"
        
        rules={[{ required: true, message: 'Plese input the new password!' },{validator:validatePassword}]}
      >
        <Input.Password onChange={handleInputN} placeholder="uppercase & lowercase letter, number, special character"/>
      </Form.Item>
      <Form.Item label="Confirm Password"
        name="confirmpassword"
        rules={[{ required: true, message: 'Plese input the confirm password!' },{validator:validateCPassword}]}
      >
        <Input.Password onChange={handleInputC} />
      </Form.Item>
      
      <Form.Item>
        <div style={{ align: 'center' }}>

          <Button onClick={hanleClose} >Close</Button>
          <Button type="primary"
            onClick={handleAdd}
            style={{
              marginLeft: 16,
            }}
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>


  )
})
export default ChangePWD
