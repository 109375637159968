import { convertToRaw,EditorState,ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import axios from 'axios';
import config from '../config'
import { S3Client, PutObjectCommand, GetObjectCommand } from "@aws-sdk/client-s3"
import {angle,array} from 'vectorious'
const { v4: uuidv4 } = require('uuid')
const s3 = new S3Client({
  region: "us-east-1",
  credentials: {
    accessKeyId: config.accessKeyId,
    secretAccessKey: config.secretAccessKey,
  },
})
export const uploadFileToS3 = async (file, folder, filename) => {
  console.log("FILE:", file)
  const command = new PutObjectCommand({
    Bucket: "session-related",
    Key: folder + "/" + filename,
    Body: file,
    ContentType: file.type,
    ACL: 'public-read'
  });

  try {
    const response = await s3.send(command);
  } catch (err) {
    console.log("Error", err);
    // return -1
  }
};

export const getEditorStateFromValue=(datafromdb)=>{
    let draft=htmlToDraft(datafromdb)
    if(draft){
        const contentState=ContentState.createFromBlockArray(draft.contentBlocks)
        const teditorState=EditorState.createWithContent(contentState)
        return teditorState
    }else{
        return ""
    }
}

export const getDataFromEditor=(editorvalue)=>{
    return draftToHtml(convertToRaw(editorvalue.getCurrentContent()))
}

export const getUUIDFileName=(oldfilename)=>{
    const fileName = `${uuidv4()}`;
    const fileExtension = oldfilename.split(".").pop()
    return fileName+"."+fileExtension
}

export const getS3ObjectUrl=(type,key)=>{
  // console.log("test")
  axios.get(`${config.getS3ObjectUrl}/${type}/${key}`).then(res=>{
    console.log(res)
    return(res.data.url)
  })
}

export const hasPermission=(needrole)=>{
  console.log(localStorage.getItem("userrole"))
  if(needrole===undefined){
    return false
  }else{
    return needrole.includes(localStorage.getItem("userrole"))
  }
}

export const angleBetweenVectors=(p0,p1,p2)=>{
  const v1=array([p1.x-p0.x,p1.y-p0.y])
  const v2=array([p2.x-p0.x,p2.y-p0.y])

  const cosAngle=angle(v1,v2)
  // const cosAngle=angle(array(v1),array(v2))
  const tmpAngle=cosAngle*(180/Math.PI)
//   const v1 = array([1, 2, 3]);
// const v2 = array([4, 5, 6]);

// const result = angle(v1, v2);
  return tmpAngle.toFixed(2)
}

// export const STSAssessment=(pose)=>{
//   const rightElbow=poses[0].keypoints[8]
//   const rightShoulder=poses[0].keypoints[6]
//   const rightWrist=poses[0].keypoints[10]
//   const tmpRAngle=angleBetweenVectors(rightElbow,rightShoulder,rightWrist)
//   if(tmpRAngle>=100){
//     // console.log("first",tCount)
//     tCount=tCount+1

//     setactiontimes(tCount)
//     // incrementCount()
//   }
//   // setCameraText('rightElbow:'+tmpRAngle)
//   setActionPercent(tmpRAngle)
//   // incrementCount()
//   const leftElbow=poses[0].keypoints[7]
//   const leftShoulder=poses[0].keypoints[5]
//   const leftWrist=poses[0].keypoints[9]
//   const tmpLAngle=iwareUtils.angleBetweenVectors(leftElbow,leftShoulder,leftWrist)


//   const rightKnee=poses[0].keypoints[14]
//   const rightHip=poses[0].keypoints[12]
//   const rightAnkle=poses[0].keypoints[16]
//   const tmpRKAngle=iwareUtils.angleBetweenVectors(rightKnee,rightHip,rightAnkle)
  
//   const leftKnee=poses[0].keypoints[13]
//   const leftHip=poses[0].keypoints[11]
//   const leftAnkle=poses[0].keypoints[15]
//   const tmpLKAngle=iwareUtils.angleBetweenVectors(leftKnee,leftHip,leftAnkle)

//   setCameraText('rightElbow:'+tmpRAngle + '\r\nleftElbow:'+tmpLAngle + '\r\nrightKnee:'+tmpRKAngle+ '\r\nleftKnee:'+tmpLKAngle+'\rn\n'+poses[0].keypoints[0].score)
  
// }

