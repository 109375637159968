import React,{useState,useRef, useEffect} from 'react'
import { Button,Form,Row,Col,Input, message,Divider } from 'antd'
// import {Editor} from 'react-draft-wysiwyg'
import axios from 'axios'
import {Editor} from 'react-draft-wysiwyg'
import { convertToRaw,EditorState,ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import "./session.css"
import config from '../config.js'
import {getEditorStateFromValue,getDataFromEditor} from "../util/common"

import ExerciseContent from './exercisecontent/ExerciseContent'
import VideoConfigured from './video/VideoConfigured'

export default function Session(props) {
    const {sessionid}=props
    
    const [oprFlag,setoprFlag] =useState(false)
    const [editorStatePostP, setEditorStatePostP] = useState("")
    const [editorStatePreK, setEditorStatePreK] = useState("")
    const [editorStatePostK, setEditorStatePostK] = useState("")
    
    const [postSPTitle,setpostSPTitle] = useState("")
    const [postPContent,setpostPContent] = useState("")
    const [preSKTitle,setpreSKTitle] = useState("")
    const [preKContent,setpreKContent] = useState("")
    const [postSKTitle,setpostSKTitle] = useState("")
    const [postKContent,setpostKContent] = useState("")

    const [warmupVideList,setwarmupVideList] = useState([])
    const [exerciseVideList,setexerciseVideList] = useState([])

    const fileForm=useRef(null)
    
    useEffect(() => {
        // console.log(sessionid)
        console.log("first")
        axios.get(`${config.getExerciseContentUrl}${sessionid}`).then(res=>{
            
            // console.log(res.data.data[0])
      
            fileForm.current.setFieldsValue({
                postptitle:res.data.data[0].postptitle,
                prektitle:res.data.data[0].prektitle,
                postktitle:res.data.data[0].postktitle
            })
            setEditorStatePostP(getEditorStateFromValue(res.data.data[0].postpcontent))
            setpostPContent(res.data.data[0].postpcontent)
            setEditorStatePreK(getEditorStateFromValue(res.data.data[0].prekcontent))
            setpreKContent(res.data.data[0].prekcontent)
            setEditorStatePostK(getEditorStateFromValue(res.data.data[0].postkcontent))
            setpostKContent(res.data.data[0].postkcontent)

        })
        axios.get(`${config.getSessionVideoListUrl}${sessionid}`).then(res=>{
            if(res.data.data.length > 0)
            {
                // console.log(res.data.data.length)
                const videolist=res.data.data
                const warmupvideolist=videolist.filter(item=>item.type===2)
                setwarmupVideList(warmupvideolist)
                const exercisevideolist=videolist.filter(item=>item.type===1)
                setexerciseVideList(exercisevideolist)
                // console.log(exercisevideolist)
            }
        })
    },[sessionid,oprFlag])
    
    const handleSave=() =>{
        fileForm.current.validateFields().then(res=>{
            // console.log(res)
            saveContents(res)
            // setFormInfo(res)
            // setCurrentStep(currentStep+1)
        }).catch(err => {console.log(err)})
        // console.log(postSPContent.length<9)
        // if(postSPTitle==""||postSKTitle==""||preSKTitle==""||postSPContent.length<9||postSKContent.length<9||preSKContent.length<9){
        //     message.error("Please input title and contents")
            
        // }
    }
    // const removeSessionVideo=(svid)=>{
    //     axios.delete(`${config.deleteSessionVideoUrl}${svid}`).then(res=>{
    //         // console.log(res)
    //         if(res.data.status===0){
    //             message.success('Deleted successfully')

    //         }
    //     })
    // }
    const hanleAddEV=(vidlist)=>{
        addSessionVideo(vidlist,sessionid,1)
    }
    function addSessionVideo(vidlist,sessionid,vtype){
        axios.post(`${config.addSessionVideoUrl}`,
            {
                params:{
                    sessionid:sessionid,
                    vtype:vtype,
                    vidlist:vidlist,
                    opruser:localStorage.getItem("username")
                }
            }
        ).then(res=>{
            console.log(res)
            if(res.data.status===0){
                message.success('Added successfully')
                setoprFlag(!oprFlag)
            }
        })
    }
    const hanleAddWV=(vidlist)=>{
        addSessionVideo(vidlist,sessionid,2)
    }
    const handleRemoveEV=(svid)=> {
        axios.delete(`${config.deleteSessionVideoUrl}${svid}`).then(res=>{
            // console.log(res)
            if(res.data.status===0){
                let tmpvlist=exerciseVideList
                tmpvlist=tmpvlist.filter(item=>item.id!==svid)
                setexerciseVideList(tmpvlist)
                message.success('Deleted successfully')

            }
        })
    }
    const handleRemoveWV=(svid)=> {
        axios.delete(`${config.deleteSessionVideoUrl}${svid}`).then(res=>{
            // console.log(res)
            if(res.data.status===0){
                let tmpvlist=warmupVideList
                tmpvlist=tmpvlist.filter(item=>item.id!==svid)
                setwarmupVideList(tmpvlist)
                message.success('Deleted successfully')
            }
        })
    }
    const saveContents = (forminfo) => {
        
        axios(config.saveExerciseContentUrl, {
            method: 'POST',
            data:{
                sessionid:localStorage.getItem("currentSessionID"),
                ...forminfo,
                postpcontent:postPContent,
                prekcontent:preKContent,
                postkcontent:postKContent
            }
        })
        //   .then((res) => console.log(res.data) )
        //   .then((res) => res.json())
          .then((res) => {
            if(res.data.status===0)
            {
                message.success('Update successfully.');
            }else{
                message.error('Update failed.');
            }
          })
          .catch((err) => {
            // console.log(err)
            message.error(err.message);
          })
          .finally(() => {
            // setUploading(false);
          });
      }
  return (
    <div className="sessionLayout">
        <div className="slLeft">
            
            <Form
                labelCol={{
                span: 3,
                }}
                wrapperCol={{
                span: 22,
                }}
                layout="horizontal"
                ref={fileForm}
                
            // onValuesChange={onFormLayoutChange}
            // disabled={componentDisabled}
            // style={{
            //   maxWidth: 600,
            // }}
            >
            <Divider style={{fontWeight:"bold"}}>Post-Session(Patient Side)</Divider>
            <Form.Item label="Title" name="postptitle" rules={[{ required: true, message: 'Please input title!' }]}>
            <Input></Input>
            </Form.Item>
            <Form.Item label="Contents" name="postpcontents" >
                <Editor
                        
                    editorStyle={{border:"1px solid #ccc",height:'100px'}}
                    editorState={editorStatePostP}
                    
                    toolbarClassName='tollbarClassName'
                    wrapperClassName='wrapperClassName'
                    onEditorStateChange={(editorState)=>setEditorStatePostP(editorState)}
                    onBlur={()=>{
                        // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
                        setpostPContent(getDataFromEditor(editorStatePostP))
                    }}
                />
            </Form.Item>
            <Divider style={{fontWeight:"bold"}}>Pre-Session(Kin Side)</Divider>
            <Form.Item label="Title" name="prektitle" rules={[{ required: true, message: 'Please input title!' }]}>
            <Input />
            </Form.Item>
            <Form.Item label="Contents" name="prekcontents" >
                <Editor
                        
                    editorStyle={{border:"1px solid #ccc",height:'100px'}}
                    editorState={editorStatePreK}
                    
                    toolbarClassName='tollbarClassName'
                    wrapperClassName='wrapperClassName'
                    
                    onEditorStateChange={(editorState)=>setEditorStatePreK(editorState)}
                    onBlur={()=>{
                        // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
                        setpreKContent(getDataFromEditor(editorStatePreK))
                    }}
                />
            </Form.Item>
            <Divider style={{fontWeight:"bold"}}>Post-Session(Kin Side)</Divider>
            <Form.Item label="Title" name="postktitle" rules={[{ required: true, message: 'Please input title!' }]}>
            <Input />
            </Form.Item>
            <Form.Item label="Contents" name="postkcontents" >
                <Editor
                        
                    editorStyle={{border:"1px solid #ccc",height:'100px'}}
                    editorState={editorStatePostK}
                    toolbarClassName='tollbarClassName'
                    wrapperClassName='wrapperClassName'
                    
                    onEditorStateChange={(editorState)=>setEditorStatePostK(editorState)}
                    onBlur={()=>{
                        // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
                        setpostKContent(getDataFromEditor(editorStatePostK))
                    }}
                />
            </Form.Item>   
        </Form>
            <Button type="primary" onClick={handleSave}>Save</Button>
        </div>
        <div className="slRight" align="center">
            <VideoConfigured title="Exercise Videos" displayclassname="videolist" vsessionid={sessionid} vtype={1} videolist={exerciseVideList} onAdd={()=>setoprFlag(!oprFlag)}></VideoConfigured>
            <VideoConfigured title="Warm Up Videos" displayclassname="videolist" vsessionid={sessionid} vtype={2} videolist={warmupVideList}  onAdd={()=>setoprFlag(!oprFlag)}></VideoConfigured>
        </div>
    </div>
  )
}
