import React, { useState, forwardRef, useRef, useEffect } from 'react';
import { PlusOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form, Input, Button, Radio, Select, Cascader, DatePicker, InputNumber, message, Checkbox, Upload,Spin } from 'antd';
import ReactPlayer from 'react-player'
import { createDetector, SupportedModels, movenet, calculators } from '@tensorflow-models/pose-detection'
import { getUUIDFileName,uploadFileToS3, getDataFromEditor,downloadFile } from "../../util/common"
import videoService from '../../services/videoService'
import moment from 'moment'

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const RetestVideo = forwardRef((props, ref) => {
  
  const [uploading, setUploading] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [movenetDetector, setMovenetDetector] = useState(false);
  const videoPlayRef = useRef(null);

  const [pname,setPname] = useState('')
  const [psession,setPsession] = useState('')
  const [pscore,setPscore] = useState('')
  const [pdate,setPdate] = useState('')
  const [videoid,setVideoID] = useState()
  const [newscore,setNewScore] = useState()
  const [difference,setDifference] = useState()
  const [pexercisename,setpexercisename] = useState('')
  const [evurl,setEVurl] = useState('')
  const [isloading,setLoadingflag] = useState(true)
  
  const downloadVideoFile=(url)=>{
    const xhr=new XMLHttpRequest();
    xhr.open('GET',url)
    xhr.responseType='blob'
    xhr.send();
    xhr.onload=function(){
      const fileBlob=xhr.response
      console.log(fileBlob)
      const fileUrl=URL.createObjectURL(fileBlob)
      setEVurl(fileUrl)
      console.log(fileUrl)
      setLoadingflag(false)
    }
  }
  useEffect(()=>{
    
    async function fetchData() {
      setLoadingflag(true)
      const res= await videoService.getExerciseVideoByID(props.id)
      console.log(res)
      if(res.code===0){
        const vinfo=res.data[0]
        setPname(vinfo.firstname + ' ' + vinfo.lastname) 
        setPsession(vinfo.sessionid)
        setPscore(vinfo.score)
        setpexercisename(vinfo.exercisename)
        setPdate(moment(vinfo.uploadtime).format('YYYY-MM-DD HH:mm:ss'))
        downloadVideoFile(vinfo.pvideourl)
        setVideoID(vinfo.videoid)
      }else{
          message.error(res.message)
      }
    }
    fetchData();
    

  },[])

  const hanleClose = () => {
    props.handleCancel()
  }
  
  const handleUpload = async () => {
    setNewScore()
    setDifference()
    const detector = await createPoseDetector();

    ref.current.validateFields().then(res => {
      setUploading(true)
      setMovenetDetector(detector);
      setPlaying(true);

    }).catch(err => { console.log(err) })
  }
  
  
  let posesList = [];
  const onVideoStarted = () => {
    posesList = [];
  }

  const onVideoProgressed = () => {
    if (movenetDetector) {
      const videoElement = videoPlayRef.current.getInternalPlayer();
      movenetDetector.estimatePoses(videoElement).then((poses) => {
        poses.forEach(pose => {
          posesList.push({
            score: pose.score,
            keypoints: calculators.keypointsToNormalizedKeypoints(pose.keypoints, {
              height: videoElement.videoHeight,
              width: videoElement.videoWidth
            })
          });
        });
      });
    }
  }
  const onVideoEnded = () => {
    setPlaying(false);
    movenetDetector.dispose();
    setMovenetDetector(null);
    // console.log(posesList)
    setUploading(false)
    videoPlayRef.current.seekTo(0)
    videoService.evalRecording(videoid,posesList).then(res => {
      if(res.status===0){
        setNewScore(res.score.toFixed(2))
        setDifference(((res.score.toFixed(2)-pscore)/pscore*100).toFixed(2)+'%')
      }
      // console.log(res)
    })
    // uploadFiles()
  }
  const createPoseDetector = async () => {
    return await createDetector(SupportedModels.MoveNet, {
      modelType: movenet.modelType.SINGLEPOSE_LIGHTNING
    });
  }
  return (
    <div style={{display:'flex'}}>
      <div style={{flex: 1}}>
      <Form
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        style={{
          // maxWidth: 900,
          
        }}
      >

        <Form.Item label="Participant">
          {pname}
        </Form.Item>
        <Form.Item label="Session">
          {psession}
        </Form.Item>
        <Form.Item label="Exercise">
          {pexercisename}
        </Form.Item>
        <Form.Item label="Exercise Date">
          {pdate}
        </Form.Item>
        <Form.Item label="Score">
          {pscore}
        </Form.Item>
        
      <Form.Item label={<span style={{ color: 'red',fontWeight:'600',fontSize:'1.2em' }}>New Score</span>}><span style={{color: 'red',fontWeight:'600',fontSize:'1.2em'}}>{newscore}</span></Form.Item>
      <Form.Item label={<span style={{ color: 'red',fontWeight:'600',fontSize:'1.2em' }}>Difference</span>}><span style={{color: 'red',fontWeight:'600',fontSize:'1.2em'}}>{difference}</span></Form.Item>
      </Form>
      </div>
      <div style={{flex:'2'}}>
      <Form
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 25,
      }}
      layout="horizontal"
      style={{
        maxWidth: 900,
        
      }}
      ref={ref}
    >

      
      
      
      <Form.Item>
        <div style={{textAlign: 'center'}}>

        <Spin tip="Loading Video" style={{align:'center',display:isloading?'':'none'}}>
          
        </Spin>
        
        <ReactPlayer
            id="test"
            playing={playing}
            ref={videoPlayRef}
            muted={true}
            width={900} 
            controls={false}
            url={evurl}
            onProgress={onVideoStarted}
            onProgress={onVideoProgressed}
            onEnded={onVideoEnded}
          />
        </div>
      </Form.Item>
      
      <Form.Item>
        <div style={{ align: 'center',paddingLeft:'50px' }}>

          <Button onClick={hanleClose} disabled={uploading}>Go Back</Button>
          <Button type="primary"
            onClick={handleUpload}
            loading={uploading}
            style={{
              marginLeft: 16,
            }}
          >
            {uploading ? 'Retesting' : 'Start Re-Test'}
          </Button>
        </div>
      </Form.Item>
    </Form>
      </div>
    </div>

  )
})
export default RetestVideo
