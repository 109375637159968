import React, { useState,forwardRef,useRef } from 'react';
import { PlusOutlined,UploadOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  message,
  Checkbox,
  Upload,
} from 'antd';
import axios from 'axios';
import config from '../../config'

const ClinicUpload=forwardRef((props,ref)=> {
    
    // const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [visitreason, setVisitReason] = useState("");
    const [type, setType] = useState("");
    // const [formInfo, setFormInfo] = useState({})
    
    // const fileForm=useRef(null)
    
    const hanleClose=()=> {
        
        props.handleCancel()
        // ref.current.setisUploadModalOpen(false)
    }
    
    
    const handleInput=(evt)=>{
        setVisitReason(evt.target.value)
        // console.log(text)
    }
    
    const handleUpload = () => {
        // console.log(ref.current)
        ref.current.validateFields(["visitflag"]).then(res=>{
            if(res.visitflag==='1'){
                ref.current.validateFields().then(res2=>{
                    console.log("first")
                    uploadLog(res2)
                })

            }else{
                uploadLog(res)
            }
        })
    }

    const uploadLog = (data) => {
        // console.log("okok")
        axios(`${config.addClinicLogUrl}`, {
        method: 'POST',
        params:{
            ...data,
            patientid:2,
            sessionid:3
        }
        })
        //   .then((res) => console.log(res) )
        //   .then((res) => res.json())
          .then((res) => {
            // console.log(res)
            if(res.data.status===0)
            {
                // setFileList([]);
                props.handleRefresh()
                props.handleCancel()
                message.success('upload successfully.');
            }
          })
          .catch(() => {
            message.error('upload failed.');
          })
          .finally(() => {
            setUploading(false);
          });
      };
  return (
      <Form
        // labelCol={{
        //   span: 5,
        // }}
        // wrapperCol={{
        //   span: 18,
        // }}
        layout="vertical"
        // onValuesChange={onFormLayoutChange}
        // disabled={componentDisabled}
        style={{
          maxWidth: 600,
        }}
        ref={ref}
        // onFinish={onFinish}
      >
        Please fill out any clinic visits you might have had over the last week.
        This will help us link your healthy lifestyle to any reduction in clinic visits.
        &nbsp;
        <Form.Item style={{textAlign:"left",verticalAlign:"top"}}
            label="1. Did you have to visit the clinic over the past week due to worsening of symptoms?"
            name="visitflag"
            rules={[{ required: true, message: 'Plese select if you have to visit the clinic!' }]}
        >
          <Radio.Group name="visitflag">
            <Radio value="1"> Yes </Radio>
            <Radio value="0"> No </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="2. If so, what was the reason for visiting the clinic over the past week?"
            name="visitreason"
            rules={[{ required: true, message: 'Plese input reason!' }]}
        >
          <Input value={visitreason} onChange={handleInput}/>
        </Form.Item>
        
        <Form.Item>
            <div style={{align: 'center'}}>

                <Button onClick={hanleClose}>Close</Button>
                <Button type="primary" 
                    onClick={handleUpload}
                    // disabled={fileList.length === 0}
                    loading={uploading}
                    style={{
                      marginLeft: 16,
                    }}
                >
                    {uploading ? 'Uploading' : 'Submit'}
                </Button>
            </div>
        </Form.Item>
      </Form>
  
  
  )
})
export default ClinicUpload
