import React, { useState,useRef } from 'react'
import { Layout,Dropdown,Space,Avatar, Menu ,Modal} from 'antd'
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    DownOutlined,
    SmileOutlined,
    UserOutlined
  } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
// import { connect } from 'react-redux';

// import store from '../../redux/store'
import {setcollapsed,setcurrentoprtitle} from '../../redux/MvpReducer'
import { useDispatch,useSelector } from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUserCog} from '@fortawesome/free-solid-svg-icons'
import ChangePWD from '../user/ChangePWD';

const adminIcon=<FontAwesomeIcon icon={faUserCog}/>

const {Header}=Layout
  
function TopHeader(props) {
  const [isUploadModalOpen,setisUploadModalOpen]=useState(false)
  const curoprtitle=useSelector((state)=>{
      return state.globalConfig.currentoprtitle
  })
  const [collapsed,setCollapsed]=useState(false)
  const colorBgContainer="white"
  const dispatch = useDispatch()
  const changePWDForm=useRef(null)
  const changeCollapse=()=>{
    dispatch(setcollapsed())
    // 
    setCollapsed(!collapsed)
  }

    // const changeCollapse=()=>{setCollapsed(!collapsed)}
    // localStorage.setItem("token",JSON.stringify({roleName:"a",username:"admin"}))
    // const {role:{roleName},username}=JSON.parse(localStorage.getItem("token"))
    const roleName=""
    const username=localStorage.getItem("username")
    const token=localStorage.getItem("token")
    // console.log(token)
    if(token===""||token===null){
        props.history.replace("/login")
    }
    const handleCancel=()=>{
      setisUploadModalOpen(false)
      changePWDForm.current.setFieldsValue({oldpassword:"",newpassword:"",confirmpassword:""})
    }
    const handleLogout=()=>{
      localStorage.removeItem("token")
      localStorage.removeItem("username")
      localStorage.removeItem("userid")
      localStorage.removeItem("userrole")
      props.history.replace("/login")
    }
    const items = [
        {
          key: '1',
        //   danger: true,
          label: 'Change Password',
          onClick: () =>{
            setisUploadModalOpen(true)
          }
        },
        {
          key: '4',
          danger: true,
          label: 'Logout',
          onClick: () =>{
            handleLogout()
          }
        },
      ];
    return (
        <Header style={{ padding: '0 16px', background: colorBgContainer}}>
          <Modal title="Change Password" open={isUploadModalOpen}  closable={false} 
            footer={null
                // [<Button type="primary" onClick={handleCancel}>Close</Button>,
                // <Button type="primary" onClick={handleUploadVideo}>Upload</Button>]
            }
            width="650px"
          >
            <ChangePWD  handleCancel={handleCancel} ref={changePWDForm} handleLogout={handleLogout}></ChangePWD>
          </Modal>
            
            {
                collapsed?<MenuUnfoldOutlined style={{fontSize:"25px"}}onClick={changeCollapse}/>:<MenuFoldOutlined style={{fontSize:"25px"}} onClick={changeCollapse}/>
            }
            <span style={{ fontSize:"25px",paddingLeft:"20px"}}>{curoprtitle}</span>
            <div style={{float:'right'}}>
                <span style={{paddingRight:"10px",fontSize:"16px"}}>Current User : <span style={{color:"blue"}}>{username}</span></span>
                
                <Dropdown menu={{items}}>
                    <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        <Avatar size="large" icon={adminIcon} />
                        <DownOutlined />
                        
                    </Space>
                    </a>
                    
                </Dropdown>
            </div>
        </Header>
    )
}

export default withRouter(TopHeader)