import React, { useState, forwardRef, useRef, useEffect } from 'react';
import { PlusOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form, Input, Button, Radio, Select, Cascader, DatePicker, InputNumber, message, Checkbox, Upload } from 'antd';
import ReactPlayer from 'react-player'
import { createDetector, SupportedModels, movenet, calculators } from '@tensorflow-models/pose-detection'
import { getUUIDFileName,uploadFileToS3, getDataFromEditor } from "../../util/common"
import {Editor} from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
// import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import videoService from '../../services/videoService'

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const VideoUpload = forwardRef((props, ref) => {
  
  const [fileList, setFileList] = useState([]);
  const [picfileList, setpicFileList] = useState([]);
  const [picFile, setpicFile] = useState(null);
  const [videoFile, setvideoFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState("");
  const [repetitions, setRepetitions] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("")
  const [imageUrl, setImageUrl] = useState();
  // const [picfilename, setPicFilename] = useState("");
  const [s3picfilename, sets3PicFilename] = useState("");
  const [s3videofilename, sets3videoFilename] = useState("");
  // const [formInfo, setFormInfo] = useState({})
  const [descriptionEditor, setDescriptionEditor] = useState(EditorState.createEmpty())

  const [videoFilePath, setVideoFilePath] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [movenetDetector, setMovenetDetector] = useState(false);
  // const [isProcessing, setIsProcessing] = useState(false);
  // const [formData, setFormData] = useState(null);
  const videoPlayRef = useRef(null);

  const [vurl, setVurl] = useState("")

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const hanleClose = () => {
    // console.log(fileList)
    // console.log(props)
    setFileList([]);
    setUploading(false)
    setpicFile(null)
    posesList = []
    // console.log(fileList)
    // ref.fileForm.resetFields()
    // console.log(title)
    setImageUrl()
    props.handleCancel()
    // ref.current.setisUploadModalOpen(false)
  }
  
  const handleInput = (evt) => {
    setTitle(evt.target.value)
    // console.log(text)
  }
  const handleInputR = (evt) => {
    setRepetitions(evt.target.value)
    // console.log(text)

  }
  const handleTextArea = (evt) => {
    setDescription(evt.target.value)
    // console.log(text)
  }

  const func = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      sets3videoFilename("")
      setvideoFile(null)
    },
    beforeUpload: (file) => {
      // console.log(file)
      // const isPNG = file.type === 'video/quicktime';
      if (file.type === 'video/quicktime' || file.type === 'video/mp4') {
        setFileList([file]);
      } else {
        message.error(`${file.name} is not a video file`);
      }
      return false;

    },
    fileList,
  };
  const funcpic = {
    onRemove: (file) => {
      const index = picfileList.indexOf(file);
      const newFileList = picfileList.slice();
      newFileList.splice(index, 1);
      setpicFileList(newFileList);
      sets3PicFilename("")
      setpicFile(null)
    },
    beforeUpload: (file) => {
      setpicFileList([file]);
      return false;

    },
    picfileList,
  };
  const handleUpload = async () => {
    const detector = await createPoseDetector();

    ref.current.validateFields().then(res => {
      setUploading(true)
      setMovenetDetector(detector);
      setPlaying(true);

    }).catch(err => { console.log(err) })
  }
  const setPicInfo = (info) => {
    // console.log(info)
    sets3PicFilename(getUUIDFileName(info.name))
    setpicFile(info)
  }
  const handleSelectPic = (info) => {

    setpicFile(picfileList[0])
    sets3PicFilename(getUUIDFileName(info.file.name))
    // console.log(info)
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    } else {
      getBase64(info.fileList[0].originFileObj, (url) => {
        // console.log(url)
        setLoading(false);
        setImageUrl(url);
      });
    }
    
  };
  const handleSelectVideo = (info) => {
    // console.log(fileList[0])
    setVideoFilePath(URL.createObjectURL(info.file))
    setvideoFile(fileList[0])
    sets3videoFilename(getUUIDFileName(info.file.name))
    // console.log(videoFile)
    // console.log(s3videofilename)
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

  };

  const uploadFiles = async () => {
    //picfile
    await uploadFileToS3(picFile, 'pictures', s3picfilename)
    //videofile
    await uploadFileToS3(videoFile, 'videos', s3videofilename)

    const formData = new FormData();
    formData.append('files[]', fileList[0]);
    formData.append('poses', JSON.stringify(posesList))
    const res= await videoService.saveVideoInfo({
      poses: JSON.stringify(posesList),
      title: title,
      type: type,
      description: description,
      uploaduser: localStorage.getItem("username"),
      picname: s3picfilename,
      repetitions: repetitions,
      videoname: s3videofilename
    })
    if(res.code===0){
      setImageUrl()
      setFileList([]);
      props.handleRefresh()
      props.handleCancel()
      message.success('upload successfully.')
    }else{
      message.error(res.message)
    }
    setUploading(false)
  };
  let posesList = [];
  const onVideoStarted = () => {
    posesList = [];
  }

  const onVideoProgressed = () => {
    if (movenetDetector) {
      const videoElement = videoPlayRef.current.getInternalPlayer();
      movenetDetector.estimatePoses(videoElement).then((poses) => {
        poses.forEach(pose => {
          posesList.push({
            score: pose.score,
            keypoints: calculators.keypointsToNormalizedKeypoints(pose.keypoints, {
              height: videoElement.videoHeight,
              width: videoElement.videoWidth
            })
          });
        });
      });
    }
  }
  const onVideoEnded = () => {
    setPlaying(false);
    movenetDetector.dispose();
    setMovenetDetector(null);
    uploadFiles()
  }
  const createPoseDetector = async () => {
    return await createDetector(SupportedModels.MoveNet, {
      modelType: movenet.modelType.SINGLEPOSE_LIGHTNING
    });
  }
  return (
    <Form
      labelCol={{
        span: 3,
      }}
      wrapperCol={{
        span: 25,
      }}
      layout="horizontal"
      style={{
        maxWidth: 900,
        
      }}
      ref={ref}
    >

      <Form.Item label="Title"
        name="title"
        rules={[{ required: true, message: 'Plese input video title!' }]}
      >
        <Input value={title} onChange={handleInput} />
      </Form.Item>
      <Form.Item label="Type"
        name="type"
        rules={[{ required: true, message: 'Plese select video type!' }]}
      >
        <Select
          onChange={(value) => setType(value)}
        >
          <Select.Option value="1" key="1" >Exercise</Select.Option>
          <Select.Option value="2" key="2">Warm Up</Select.Option>
          <Select.Option value="3" key="3">Assessment</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Repetitions"
        name="repetitions"
        rules={[{ required: true, message: 'Plese input the number of repetitions!' }]}
      >
        <Input value={repetitions} onChange={handleInputR} />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Editor
         
          editorStyle={{ border: "1px solid #ccc", height: '100px' }}
          editorState={descriptionEditor}

          // toolbarClassName='tollbarClassName'
          // wrapperClassName='wrapperClassName'
          onEditorStateChange={(editorState) => setDescriptionEditor(editorState)}
          onBlur={() => {
            setDescription(getDataFromEditor(descriptionEditor))
          }}
        />
      </Form.Item>

      <Form.Item label="Thumbnail"
        name="avatar"
      >
        <Upload  {...funcpic} accept="image/*"
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          onChange={handleSelectPic}
          maxCount={1}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              style={{
                width: '120px',
                height: '120px'
              }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
        
      </Form.Item>
      <Form.Item label="Video"
        // name="filename"
        rules={[{ required: true, message: 'Plese select video file!' }]}
      >
        <Upload  {...func} accept="video/*"
          onChange={handleSelectVideo}
        >
          <Button icon={<UploadOutlined />}>Select Video File</Button>
        </Upload>
        <ReactPlayer
          style={{ display: "none" }}
          id="test"
          playing={playing}
          ref={videoPlayRef}
          muted={true}
          width={900} height={500}
          controls={false}
          url={videoFilePath}
          onProgress={onVideoStarted}
          onProgress={onVideoProgressed}
          onEnded={onVideoEnded}
        />
      </Form.Item>
      <Form.Item>
        <div style={{ align: 'center' }}>

          <Button onClick={hanleClose} disabled={uploading}>Close</Button>
          <Button type="primary"
            onClick={handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{
              marginLeft: 16,
            }}
          >
            {uploading ? 'Processing & Uploading' : 'Start Process & Upload'}
          </Button>
        </div>
      </Form.Item>
    </Form>


  )
})
export default VideoUpload
