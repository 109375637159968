import { useState, useEffect, useRef, useCallback } from 'react'
import { Icon } from '@iconify/react';
import closeIcon from '@iconify/icons-eva/close-fill';
import playIcon from '@iconify/icons-eva/play-circle-fill';
import stopPlayIcon from '@iconify/icons-eva/stop-circle-outline';
import recordIcon from '@iconify/icons-eva/recording-outline';
import configCamerIcon from '@iconify/icons-eva/camera-fill';
import {
    Backdrop,
    Button,
    Stack,
    Typography
} from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Webcam from "react-webcam";
import '@tensorflow/tfjs-backend-webgl';
import { createDetector, SupportedModels, movenet, calculators } from '@tensorflow-models/pose-detection';

import Page from '../../components/Page';
import TestCameraDialog from '../../components/TestCameraDialog';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';
import MessageDialog from '../../components/MessageDialog';
import videoService from '../../services/videoService';


let detector = null;

export default function Activity() {

    const { id } = useParams()
    const videoPlayRef = useRef(null);
    const webCamRef = useRef(null);
    const instructionRecordingRef = useRef(null);
    const recordingRef = useRef(null);
    const webCamDivRef = useRef(null);
    const [score,setScore]=useState(0)
    const [isRecording, setIsRecording] = useState(false);
    const [cameraDevices, setCameraDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);
    const [showCountdown, setShowCountdown] = useState(false);
    const [recordButtonText, setRecordButtonText] = useState('Record');
    const [recordButtonIcon, setRecordButtonIcon] = useState(recordIcon);
    const [videoButtonIcon, setVideoButtonIcon] = useState(playIcon);
    const [playing, setPlaying] = useState(false);
    const [playButtonText, setPlayButtonText] = useState('Play Video');
    const [videoUrl, setVideoUrl] = useState('');
    const [key, setKey] = useState(0);
    const [testCameraDialogOpen, setTestCameraDialogOpen] = useState(false);
    const [videoPlayProgress, setVideoPlayProgress] = useState(0);
    // const [detector, setDetector] = useState(null);
    const [posesList, setPosesList] = useState([]);
    const [messageDialogOpen, setMessageDialogOpen] = useState(false);
    const [messageDialogTitle, setMessageDialogTitle] = useState('');
    const [messageDialogMessage, setMessageDialogMessage] = useState('');
    const [messageDialogChoiceTopic, setMessageDialogChoiceTopic] = useState('');
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [cameraText, setCameraText] = useState('');
    const navigate = useHistory();

    const onClose = (e) => {
        e.stopPropagation();
        navigate.push('/video/videolist');
    };
    const onTestCameraCancel = () => {
        setTestCameraDialogOpen(false);
    };
    const onRecord = (e) => {
        setIsRecording(!isRecording);
        setVideoPlayProgress(0);
        e.stopPropagation();
    };
    const onTestCameraClicked = (e) => {
        setTestCameraDialogOpen(true);
    };
    const onVideoPlayProgrss = () => {
        if (videoPlayRef.current) {
            if (playing) {
                if (detector) {
                    var videoElement = document.getElementById('recording-camera');
                    detector.estimatePoses(videoElement).then((poses) => {
                        poses.forEach(pose => {
                            posesList.push({
                                score: pose.score,
                                keypoints: calculators.keypointsToNormalizedKeypoints(pose.keypoints, {
                                    height: videoElement.videoHeight,
                                    width: videoElement.videoWidth
                                })
                            });
                        });
                    });
                }
            }
            const total = videoPlayRef.current.getDuration();
            const played = videoPlayRef.current.getCurrentTime();
            if (total && played) {
                setVideoPlayProgress(played * 100 / total);
            }
        }
    };
    // console.log(id)
    useEffect(() => {

        setRecordButtonText(isRecording ? 'Stop Recording' : 'Record');
        setRecordButtonIcon(isRecording ? stopPlayIcon : recordIcon);
        if (isRecording) {
            // setShowCountdown(true);
            setKey(prevKey => prevKey + 1);
        }
        else {
            setPlaying(false);
            if (recordingRef.current && recordingRef.current.state === 'recording') {
                recordingRef.current.stop();
            }
        }
    }, [isRecording]);

    useEffect(() => {
        setPlayButtonText(playing ? 'Stop Video' : "Play Video");
        setVideoButtonIcon(playing ? stopPlayIcon : playIcon);
    }, [playing]);

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
            videoService.getVideoStream(id).then((url) => {
                setVideoUrl(url);
            });
            const videoInputs = [];
            for (const device of devices) {
                if (device.kind === 'videoinput') {
                    videoInputs.push({
                        deviceId: device.deviceId,
                        label: device.label
                    });
                }
            }
            setCameraDevices(videoInputs);
            if (videoInputs.length > 0) {
                setSelectedDeviceId(videoInputs[0].deviceId);
            }
        });
    }, []);
    const onPlay = (e) => {
        const existingPlayingState = playing;
        setPlaying(!playing);
        if (existingPlayingState) {
            videoPlayRef.current.seekTo(0);
        }
        e.stopPropagation();
    };
    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );
    const onCountdownComplete = () => {
        setShowCountdown(false);
        videoPlayRef.current.seekTo(0);
        setPlaying(true);
        setRecordedChunks([]);
        recordingRef.current = new MediaRecorder(webCamRef.current.stream, {
            mimeType: "video/webm"
        });
        recordingRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        recordingRef.current.start();
    };
    const instructionCameraDataAvailable = useCallback(
        () => {
            
            var videoElement = document.getElementById('recording-camera');
            detector.estimatePoses(videoElement).then((poses) => {
                console.log(poses)
                if (!(poses && poses.length > 0)) {
                    return;
                }
                let pose = poses[0];
                for (const kp of pose.keypoints) {
                    if (kp.score < 0.4) {
                        return;
                    }
                }
                // instructionRecordingRef.current.stop();
                instructionRecordingRef.current.removeEventListener('dataavailable', instructionCameraDataAvailable);
                setCameraText('Perfect! Please hold and the recording will start soon!');
                setTimeout(() => {
                    setShowCountdown(true);
                    setCameraText('');
                    webCamDivRef.current.style.zIndex=-1;
                    webCamDivRef.current.style.position='fixed';
                    webCamDivRef.current.style.right='10px';
                    webCamDivRef.current.style.bottom='10px';
                    webCamDivRef.current.style.top=null;
                    webCamDivRef.current.style.left=null;
                }, 2000);
            });
        },
        [instructionRecordingRef]
    );

    const checkCamera = () => {

        setCameraText('Please move your entire body in camera');
        instructionRecordingRef.current = new MediaRecorder(webCamRef.current.stream, {
            mimeType: "video/webm"
        });
        instructionRecordingRef.current.addEventListener(
            "dataavailable",
            instructionCameraDataAvailable
        );
        instructionRecordingRef.current.start(1000);
    };
    const onCameraReady = () => {
        createDetector(SupportedModels.MoveNet, {
            modelType: movenet.modelType.SINGLEPOSE_LIGHTNING
        }).then((res) => {
            detector = res;
            // setDetector(res);
            setPosesList([]);
            checkCamera();
        });
    };
    const onVideoEnded = () => {
        setPlaying(!playing)
        
        // if(isRecording){
            // evalRecording(id, posesList).then((res) => {
                setScore(98)
                setMessageDialogTitle('Recording Complete');
                setMessageDialogMessage(`Your score is ${score} out of 100! Do you want to upload your recording for your doctor to review?`);
                // setMessageDialogMessage(`Your score is ${Math.round(res.score * 100) / 100} out of 100! Do you want to upload your recording for your doctor to review?`);
                setMessageDialogChoiceTopic('uploadRecording');
                setMessageDialogOpen(true);
            // });
        // }
        setIsRecording(false);
        
    };
    const onMessageDialogYes = (topic) => {

        if (topic === 'uploadRecording') {
            // console.log("uploading")
            var reader = new FileReader();
            reader.readAsDataURL(new Blob(recordedChunks, {
                type: "video/webm"
            }));
            reader.onloadend = function () {
                var recordingContent = reader.result;
                videoService.uploadRecording(id, posesList, recordingContent,score);
            }
        }
    };

    const renderTime = ({ remainingTime }) => {

        return (
            <div>
                <div style={{ fontSize: "80px" }}>{remainingTime}</div>
            </div>
        );
    };

    return (
        <Page title="iWareHealth | Prescription Activity">
            <Stack sx={{ height: '100%' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Cardiac Rehabilitation Exercises
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        startIcon={<Icon icon={closeIcon} />}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </Stack>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={showCountdown}
                // invisible={true}
                >
                    <div>
                        <CountdownCircleTimer
                            isPlaying={showCountdown}
                            key={key}
                            duration={10}
                            size="300"
                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                            onComplete={onCountdownComplete}
                        >
                            {renderTime}
                        </CountdownCircleTimer>
                    </div>
                </Backdrop>
                <TestCameraDialog open={testCameraDialogOpen}
                    onCancel={onTestCameraCancel}
                    devices={cameraDevices}
                    selectedDeviceId={selectedDeviceId} />
                <MessageDialog
                    open={messageDialogOpen}
                    title={messageDialogTitle}
                    message={messageDialogMessage}
                    onClose={() => setMessageDialogOpen(false)}
                    onYes={(topic) => {
                        setMessageDialogOpen(false);
                        onMessageDialogYes(topic);
                    }}
                    choisTopic={messageDialogChoiceTopic}
                />
                <div style={{ flexGrow: 1 }}>
                    <Stack direction="row">
                        <Button variant="contained"
                            style={{ margin: 2 }}
                            startIcon={<Icon icon={configCamerIcon} />}
                            color="secondary"
                            onClick={onTestCameraClicked}
                            size="large"
                            disabled={isRecording}
                        >
                            Test Camera
                        </Button>
                        <Button variant="contained"
                            style={{ margin: 2 }}
                            startIcon={<Icon icon={videoButtonIcon} />}
                            color="secondary"
                            size="large"
                            onClick={onPlay}
                            disabled={isRecording}
                        >
                            {playButtonText}
                        </Button>
                        <Button variant="contained"
                            startIcon={<Icon icon={recordButtonIcon} />}
                            color="primary"
                            style={{ margin: 2 }}
                            size="large"
                            onClick={onRecord}
                        >
                            {recordButtonText}
                        </Button>
                        {isRecording && (
                            <div style={{ marginLeft: 10, marginTop: 5 }}>
                                <CircularProgressWithLabel value={videoPlayProgress} />
                            </div>
                        )}
                    </Stack >
                    <div style={{width: '100%', height: '100%',position: 'relative'}}>
                        {isRecording && (
                            <div ref={webCamDivRef} style={{ position: 'absolute', left: 0, top: 0, zIndex: 1000 }}>
                                <Webcam id="recording-camera"
                                    audio={false}
                                    screenshotFormat="image/jpeg"
                                    onUserMedia={onCameraReady}
                                    videoConstraints={{
                                        deviceId: selectedDeviceId,
                                        width: 1000,
                                        height: 830
                                    }}
                                    ref={webCamRef}
                                />
                                <Typography align='center' variant='h1' color='orange' style={{ position: 'absolute', top: '5%', zIndex: 1001 }}>
                                    {cameraText}
                                </Typography>
                            </div>
                        )}
                        <div style={{ position: 'absolute', left: 0, top: 0,border:"1px solid red" }}>
                            <ReactPlayer playing={playing} ref={videoPlayRef}
                                muted={true}
                                width={1000} height={460}
                                controls={false} url={videoUrl}
                                onProgress={onVideoPlayProgrss}
                                onEnded={onVideoEnded}
                            />
                        </div>
                    </div>
                </div>
            </Stack>
        </Page>
    );
}
