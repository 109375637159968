import { configureStore } from "@reduxjs/toolkit";
import MvpReducer from "./MvpReducer";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'
import storage from "redux-persist/lib/storage"

const persistConfig ={
    key:"root",
    version: 1,
    storage,
    blacklist:["currentoprtitle"]
}

const persistedReducer =persistReducer(persistConfig,MvpReducer.reducer)

const store=configureStore({
    // reducer:{sidemenuFlag:persistedReducer}
    reducer: {globalConfig:persistedReducer},
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
})
const persistor =persistStore(store)
export {store,persistor}