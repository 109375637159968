import React, { useState, forwardRef, useRef, useEffect } from 'react';
import { Form, Input, Button, Select, message,  } from 'antd';

import adminService from '../../services/adminService'
import config from '../../config';

const UserAdd = forwardRef((props, ref) => {
  
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  

  const hanleClose = () => {
  
    props.handleCancel()
  }
  
  const handleInput = (evt) => {
    setTitle(evt.target.value)
  }
  const handleInputEmail = (evt) => {
    setEmail(evt.target.value)
  }
  
  const handleAdd = async () => {
    
    ref.current.validateFields().then(res => {
      console.log(res)
      addAdmin(res)
    }).catch(err => { console.log(err) })

  };
  const addAdmin=async (userinfo)=>{
    const res= await adminService.addUser(userinfo)
    console.log('eeeeeeee',res)
    if(res.status ===1){
      message.error(res.message)
    }else{
      message.success('Added successfully.')
      props.handleRefresh()
      props.handleCancel()
    }
  }
  
  return (
    <Form
      labelCol={{
        span: 5,
      }}
      wrapperCol={{
        span: 18,
      }}
      layout="horizontal"
      style={{
        maxWidth: 900,
        
      }}
      ref={ref}
    >

      <Form.Item label="User Account"
        name="username"
        rules={[{ required: true, message: 'Plese input user account!' }]}
      >
        <Input value={title} onChange={handleInput} />
      </Form.Item>
      <Form.Item label="User Type"
        name="type"
        rules={[{ required: true, message: 'Plese select User type!' }]}
      >
        <Select
          onChange={(value) => setType(value)}
        >
          <Select.Option value="1" key="1" >Administrator</Select.Option>
          <Select.Option value="2" key="2">Kinesiologist</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Email"
        name="email"
      >
        <Input value={email} onChange={handleInputEmail} />
      </Form.Item>
      <Form.Item label="Default Password"
      >
        <div style={{fontWeight:'600',color:'red',fontSize:'20px'}}>{config.resetPasswordString}</div>
      </Form.Item>
      <Form.Item>
        <div style={{ align: 'center' }}>

          <Button onClick={hanleClose} >Close</Button>
          <Button type="primary"
            onClick={handleAdd}
            style={{
              marginLeft: 16,
            }}
          >
            Add
          </Button>
        </div>
      </Form.Item>
    </Form>


  )
})
export default UserAdd
