import React,{useEffect, useState,useRef} from 'react'
import { Space, Table, Button, Modal, message  } from 'antd';
import './videolist.css'


import VideoUpload from '../../components/video/VideoUpload';
import VideoUpdate from '../../components/video/VideoUpdate';

import ReactPlayer from 'react-player'
import { useHistory, useParams } from 'react-router-dom'
import {EditOutlined,DeleteOutlined,ExclamationCircleOutlined} from '@ant-design/icons'
import {setcollapsed,setcurrentoprtitle} from '../../redux/MvpReducer'
import { useDispatch,useSelector } from 'react-redux';
import videoService from '../../services/videoService';
import parse from 'html-react-parser'

export default function VideoList() {
    const [dataSource,setdataSource]=useState([])
    const [isModalOpen,setisModalOpen]=useState(false)
    const [isUploadModalOpen,setisUploadModalOpen]=useState(false)
    const [isUpdateModalOpen,setisUpdateModalOpen]=useState(false)
    const [currentDescription,setcurrentDescription]=useState("")
    const [currentVideoID,setcurrentVideoID]=useState("")
    const uploadForm=useRef(null)
    const navigate = useHistory()
    const dispatch = useDispatch()
    const columns = [
        {
          title: 'Video Name',
        //   dataIndex: 'title',
        //   key: 'id',
          render: (item) => <a onClick={()=>{
            setisModalOpen(true)
            setcurrentDescription(parse(item.description))
        }} title="Show descriptions">{item.title}</a>,
        },
        {
          title: 'Thumbnail',
          dataIndex: 'picurl',
        //   key: 'age',
          width:'140px',
          align: 'center',
          render: (picurl) => (
            <img style={{borderRadius:'8px'}} src={picurl} height="120px" width="120px" />
          ),
        },
        {
          title: 'Content',
          dataIndex: 'videourl',
        //   key: 'age',
          width:'330px',
          align: 'center',
          render: (videourl) => (
            <ReactPlayer controls url={videourl} height="150px" width="266px" />
          ),
        },
        {
          title: 'Category',
          dataIndex: 'type',
          align: 'center',
          width:'140px',
          render: (type) => (
            // item.type=="1"?'Exercise':'Warm up'
            type===1?"Exercise":type===2?"Warm up":"Assessment"
          ),
        //   key: 'address',
        },
        {
          title: 'Repetitions',
          width:'140px',
        //   key: 'tags',
          align: 'center',
          dataIndex: 'repetitions',
        },
        {
          title: 'Action',
          key: 'action',
          align: 'center',
          render: (item) => (
            <Space size="middle">
              <Button size="small" onClick={()=>handlePopupUpdate(item.id)}>Update</Button>
              <Button size="small" onClick={()=>confirm(item)} type="primary" danger>Delete</Button>
            </Space>
          ),
        },
      ];
      // const browseVideo=(id)=> {
      //   navigate.push(`/video/videolist/${id}`)
      // }
      const confirm=(item) =>{
        Modal.confirm({
            title: 'Delete Confirm',
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure you want to delete this video? 
            This video will no longer appear in all Sessions related to this video.`,
            okText: 'Yes',
            cancelText: 'No',
            onOk(){
                deleteMethod(item)
            }
          });
      }
      const deleteMethod=async (item)=>{
        
        let list=dataSource.filter(data=>data.id!==item.id)
        setdataSource([...list])
        const res= await videoService.deleteVideoByID(item.id)
        
        if(res.code===0){
          message.success('delete successfully.')
        }else{
          message.error('delete failed!')
        }
    }
    useEffect(()=>{
        dispatch(setcurrentoprtitle(" Video List"))
    },[])
    useEffect(()=>{
      
      fetchData()
    },[])
    const fetchData=async () =>{
      const res= await videoService.getVideoList()
      if(res.code===0){
        setdataSource(res.data)
      }else{
          message.error(res.message)
      }
    }
    const handleCancel=()=>{
        setisUploadModalOpen(false)
        uploadForm.current.setFieldsValue({title:"",fileList:[],type:"",description:"",imageUrl:"",repetitions:""})
    }
    const handleCancelUpdate=()=>{
      setisUpdateModalOpen(false)
      
  }
    const handleCancelDescription=()=>{
      setisModalOpen(false)
      
  }
    const handlePopupUpload=()=>{
        setisUploadModalOpen(true)
    }
    const handlePopupUpdate=(id)=>{
        setcurrentVideoID(id)
        setisUpdateModalOpen(true)
    }
    const handleRefresh=async ()=>{
      fetchData()
    }
  return (
    <div>
      
        <Modal title="Video Upload" open={isUploadModalOpen}  closable={false} 
            footer={null
                // [<Button type="primary" onClick={handleCancel}>Close</Button>,
                // <Button type="primary" onClick={handleUploadVideo}>Upload</Button>]
            }
            width="950px"
        >
          <div zIndex="9999">
            <VideoUpload  handleCancel={handleCancel} handleRefresh={handleRefresh} ref={uploadForm}></VideoUpload>
          </div>
        </Modal>
        <Modal title="Video Update" open={isUpdateModalOpen}  closable={false} 
            footer={null
                // [<Button type="primary" onClick={handleCancel}>Close</Button>,
                // <Button type="primary" onClick={handleUploadVideo}>Upload</Button>]
            }
            width="950px"
        >
            <VideoUpdate handleCancel={handleCancelUpdate} videoID={currentVideoID} handleRefresh={handleRefresh} ></VideoUpdate>
        </Modal>
        <Button type="primary" onClick={handlePopupUpload} style={{marginBottom:'10px'}}>Upload Video</Button>
        {/* <ReactPlayer url='https://www.youtube.com/watch?v=LBQvUXoTHp4' height="180px" width="320px" /> */}
    <Table columns={columns} dataSource={dataSource} scroll={{y:'calc(100vh - 310px)'}}
        pagination={{
            pageSize: 5,
            // hideOnSinglePage:true
        }}
        rowKey={(item)=>item.id}
    />
    <Modal title="Video Descriptions" open={isModalOpen}  closable={false} onCancel={handleCancel}
        footer={
            [<Button key="btnVD" type="primary" onClick={handleCancelDescription}>Close</Button>]
        }
    >
       {currentDescription}
    </Modal>
    </div>  
  )
}
