import React,{useState,useRef, useEffect} from 'react'
import VideoConfigured from '../../components/video/VideoConfigured'
import config from '../../config.js'
import axios from 'axios'
import {setcollapsed,setcurrentoprtitle} from '../../redux/MvpReducer'
import { useDispatch,useSelector } from 'react-redux';

export default function FreeVideoList() {
    const [freeVideList,setfreeVideList] = useState([])
    const [oprFlag,setoprFlag] =useState(false)
    const dispatch = useDispatch()

    useEffect(()=>{
        axios.get(`${config.getSessionVideoListUrl}${0}`).then(res=>{
            if(res.data.data.length > 0)
            {
                // console.log(res.data.data.length)
                const videolist=res.data.data
                setfreeVideList(videolist)
            }
        })
    },[oprFlag])
    useEffect(()=>{
        dispatch(setcurrentoprtitle("Free Exercise Video List"))
    },[])
  return (
    
    <div><VideoConfigured title="Free Exercise Videos" displayclassname="videolisth" vsessionid={0} vtype={1} videolist={freeVideList} onAdd={()=>setoprFlag(!oprFlag)}></VideoConfigured></div>
  )
}
