import axios from 'axios';
import config from '../config.js';


export default{
  async doTest(id)  {
   
    // const jwt = localStorage.getItem('jwt');
    const options = {
        url: `${config.angleTestUrl}${id}`,
        method: 'GET',
        // headers: {
        //     'Authorization': jwt
        // },
        timeout: config.defaultTimeout
    };
    const res = await axios(options);
    return res.data
  },
}