import React from 'react'
import {BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom'
import Login from '../views/login/Login'
import Home from '../views/home/Home'


export default function IndexRouter() {
    return (
        <BrowserRouter>
            <Switch>

                <Route path="/login" component={Login} />
                <Route path="/" component={Home} />
                {/* <Route path="/" render={()=>
                    isAuth()?<NewsSandBox></NewsSandBox>:<Redirect to="/login"/>
                }/> */}

            </Switch>

        </BrowserRouter>
    )
}