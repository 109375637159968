import React, { useState,forwardRef,useRef, useEffect } from 'react';
import { PlusOutlined,UploadOutlined,LoadingOutlined } from '@ant-design/icons';
import {Form,Input,Button,Radio,Select,Cascader,Col,message,Checkbox,Upload,Row} from 'antd';
import {Editor} from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import assessmentService from '../../services/assessmentService';
import {getEditorStateFromValue,getDataFromEditor} from "../../util/common"


const AVideoMultilingual=((props)=> {
    const vid=props.videoID
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState("");
    const [langid, setLangID] = useState("");
    const [lang, setLang] = useState("fr");
    const [multiligual, setMultiligual] = useState([]);
    const [description, setDescription] = useState("")
    const [descriptionEditor, setDescriptionEditor] = useState(EditorState.createEmpty())
    const [imageUrl, setImageUrl] = useState();
    

    const videoFormRef = useRef(null);

    const setLangInfo=(langlist,lang)=> {
      // console.log(multiligual)
      const vinfo=langlist.find(item=>item.lang===lang)
      // console.log(vinfo)
      if(vinfo){
        videoFormRef.current.setFieldsValue({
          title:vinfo.title
        })
        setDescriptionEditor(getEditorStateFromValue(vinfo.description))
        setLangID(vinfo.id)
      }else{
        videoFormRef.current.setFieldsValue({
          title:''
        })
        setDescriptionEditor('')
        setLangID('')
      }
    }
    
    useEffect(()=>{
        
      async function fetchData() {
        const res= await assessmentService.getVideoMulLangById(vid)
        // console.log(res)  
        if(res.code===0){
          if(res.data.length>0){
            setMultiligual(res.data)
            // setLangInfo(res.data,'fr')
          }
          // videoFormRef.current.setFieldsValue({
          //     title:vinfo.title
          // })
          // setImageUrl(vinfo.picurl)
          // setDescriptionEditor(getEditorStateFromValue(vinfo.description))
          // setDescription(vinfo.description)
        }else{
          message.error(res.message)
        }
      }
      fetchData();
        
    },[props])

    const hanleClose=()=> {
      videoFormRef.current.setFieldsValue({
        title:'',
        lang:''
      })
      setDescriptionEditor('')
      setUploading(false)
      setLang('')
      props.handleCancel()
    }
    
    const handleInput=(evt)=>{
        setTitle(evt.target.value)
        // console.log(text)
    }
    const handleUpdate = async () => {
        // console.log("----------------------")
        videoFormRef.current.validateFields().then(res=>{
            assessmentService.addMultilingual(langid,vid,lang,res.title,description).then(result=>{
              if(result.code===0){
                setLang('fr')
                hanleClose()
                props.handleRefresh()
                message.success('Successfully updated')
              }else{
                message.error('Error updating')
              }
            })
            
        }).catch(err => {console.log(err)})
    }

  return (
    <div style={{zIndex:'9999'}}>
      <Form
        labelCol={{
          span: 3,
        }}
        wrapperCol={{
          span: 25,
        }}
        layout="horizontal"
        style={{
          maxWidth: 900,
          zIndex:9999,
        }}
        ref={videoFormRef}
      >
        <Form.Item label="Language"
            name="lang"
            rules={[{ required: true, message: 'Plese select language!' }]}
        >
          <Select
            onChange={(value)=>{setLang(value)
              setLangInfo(multiligual,value)
              }
            }
          >
            <Select.Option value="fr" key="1" >fr</Select.Option>
            <Select.Option value="cn" key="2" >cn</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Title"
            name="title"
            rules={[{ required: true, message: 'Plese input video title!' }]}
        >
          <Input onChange={handleInput}/>
        </Form.Item>
        
        <Form.Item label="Description" name="description">
        <Editor
                        
            editorStyle={{border:"1px solid #ccc",height:'100px',zIndex:'9999'}}
            editorState={descriptionEditor}
            
            // toolbarClassName='tollbarClassName'
            // wrapperClassName='wrapperClassName'
            onEditorStateChange={(editorState)=>setDescriptionEditor(editorState)}
            onBlur={()=>{
                setDescription(getDataFromEditor(descriptionEditor))
            }}
        />
        </Form.Item>
        
        
        
        <Form.Item>
            <div style={{align: 'center'}}>

                <Button onClick={hanleClose} disabled={uploading}>Close</Button>
                <Button type="primary" 
                    onClick={handleUpdate}
                    loading={uploading}
                    style={{
                      marginLeft: 16,
                    }}
                >
                    {uploading ? 'Updating...' : 'Update & Close'}
                </Button>
            </div>
        </Form.Item>
      </Form>
    </div>
  
  )
})
export default AVideoMultilingual
