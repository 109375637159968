import React, { useEffect,useState } from 'react'
import {  Redirect, Route, Switch } from 'react-router-dom'

import VideoList from '../../views/video-manage/VideoList'
import AVideoList from '../../views/assessment/AVideoList'
import ComTest from '../../views/assessment/ComponentTest'
import AdminList from '../../views/user-manage/AdminList'
import FreeVideoList from '../../views/video-manage/FreeVideoList'
import Page404 from './Page404'
import Page403 from './Page403'
import Activity from '../../views/patientside/Activity'
import DistanceAI from '../../views/patientside/DistanceAI'
import AngleTest from '../../views/patientside/AngleAI'
import AssessmentAI from '../../views/patientside/AssessmentAI'
import SessionList from '../../views/session-manage/SessionList'
import ClinicLog from '../../views/cliniclog-manage/ClinicLogList'
import MyExcercises from '../video/MyExcercises'
import Login from '../../views/login/Login'
import ExerciseVideoList from '../../views/video-manage/ExerciseVideoList'


export default function ContentRouter() {
  return (
    <Switch>
        <Route path="/admin/videotest" component={Activity} exact></Route>
        <Route path="/resource/videolist" component={VideoList} exact></Route>
        <Route path="/admin/adminlist" component={AdminList} exact></Route>
        <Route path="/resource/freeexerciselist" component={FreeVideoList} exact></Route>
        {/* <Route path="/resource/myexercisevideolist" component={MyExcercises} exact></Route> */}
        <Route path="/session/sessionlist" component={SessionList} exact></Route>
        <Route path="/patient/patientcliniclog" component={ClinicLog} exact></Route>
        <Route path="/patient/exercisevideolist" component={ExerciseVideoList} exact></Route>
        <Route path="/unauthorized" component={Page403} ></Route>
        <Route path="/ai/distance" component={DistanceAI} ></Route>
        <Route path="/ai/assessment" component={AssessmentAI} ></Route>
        <Route path="/ai/angletest" component={AngleTest} ></Route>
        <Route path="/assessment/videolist" component={AVideoList} ></Route>
        <Route path="/assessment/comtest" component={ComTest} ></Route>
        <Route path="*" component={Page404} ></Route>
    </Switch>
  )
}
