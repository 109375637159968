import React, { useState, forwardRef, useRef, useEffect } from 'react';
import { PlusOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form, Input, Button, Radio, Select, Cascader, DatePicker, InputNumber, message, Checkbox, Upload } from 'antd';
import { getUUIDFileName,uploadFileToS3, getDataFromEditor } from "../../util/common"
import {Editor} from 'react-draft-wysiwyg'
import { EditorState } from 'draft-js'
import assessmentService from '../../services/assessmentService'

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const AVideoUpload = forwardRef((props, ref) => {
  
  const [fileList, setFileList] = useState([]);
  const [picfileList, setpicFileList] = useState([]);
  const [picFile, setpicFile] = useState(null);
  const [videoFile, setvideoFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [lang, setLang] = useState("");
  const [description, setDescription] = useState("")
  const [imageUrl, setImageUrl] = useState();
  // const [picfilename, setPicFilename] = useState("");
  const [s3picfilename, sets3PicFilename] = useState("");
  const [s3videofilename, sets3videoFilename] = useState("");
  // const [formInfo, setFormInfo] = useState({})
  const [descriptionEditor, setDescriptionEditor] = useState(EditorState.createEmpty())

  const [videoFilePath, setVideoFilePath] = useState(null);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const hanleClose = () => {
    setFileList([]);
    setUploading(false)
    setpicFile(null)
    setImageUrl()
    props.handleCancel()
  }
  
  const handleInput = (evt) => {
    setTitle(evt.target.value)
    // console.log(text)
  }
  
  // const handleTextArea = (evt) => {
  //   setDescription(evt.target.value)
  //   // console.log(text)
  // }

  const func = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      sets3videoFilename("")
      setvideoFile(null)
    },
    beforeUpload: (file) => {
      // console.log(file)
      // const isPNG = file.type === 'video/quicktime';
      if (file.type === 'video/quicktime' || file.type === 'video/mp4') {
        setFileList([file]);
      } else {
        message.error(`${file.name} is not a video file`);
      }
      return false;

    },
    fileList,
  };
  const funcpic = {
    onRemove: (file) => {
      const index = picfileList.indexOf(file);
      const newFileList = picfileList.slice();
      newFileList.splice(index, 1);
      setpicFileList(newFileList);
      sets3PicFilename("")
      setpicFile(null)
    },
    beforeUpload: (file) => {
      setpicFileList([file]);
      return false;

    },
    picfileList,
  };
  const handleUpload = async () => {
    
    ref.current.validateFields().then(res => {
      setUploading(true)
      uploadFiles()

    }).catch(err => { console.log(err) })
  }
  // const setPicInfo = (info) => {
  //   // console.log(info)
  //   sets3PicFilename(getUUIDFileName(info.name))
  //   setpicFile(info)
  // }
  const handleSelectPic = (info) => {

    setpicFile(picfileList[0])
    sets3PicFilename(getUUIDFileName(info.file.name))
    // console.log(info)
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    } else {
      getBase64(info.fileList[0].originFileObj, (url) => {
        // console.log(url)
        setLoading(false);
        setImageUrl(url);
      });
    }
    
  };
  const handleSelectVideo = (info) => {
    // console.log(fileList[0])
    setVideoFilePath(URL.createObjectURL(info.file))
    setvideoFile(fileList[0])
    sets3videoFilename(getUUIDFileName(info.file.name))
    // console.log(videoFile)
    // console.log(s3videofilename)
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }

  };

  const uploadFiles = async () => {
    //picfile
    await uploadFileToS3(picFile, 'pictures', s3picfilename)
    //videofile
    await uploadFileToS3(videoFile, 'videos', s3videofilename)

    const formData = new FormData();
    formData.append('files[]', fileList[0]);
    const res= await assessmentService.saveVideoInfo({
      lang:lang,
      title: title,
      type:type,
      description: description,
      uploaduser: localStorage.getItem("username"),
      picname: s3picfilename,
      videoname: s3videofilename
    })
    if(res.code===0){
      setImageUrl()
      setFileList([]);
      props.handleRefresh()
      props.handleCancel()
      message.success('upload successfully.')
    }else{
      message.error(res.message)
    }
    setUploading(false)
  };
  
  
  return (
    <Form
      labelCol={{
        span: 3,
      }}
      wrapperCol={{
        span: 25,
      }}
      layout="horizontal"
      style={{
        maxWidth: 900,
        
      }}
      ref={ref}
    >
      <Form.Item label="Language"
        name="lang"
        rules={[{ required: true, message: 'Plese select language!' }]}
      >
        <Select
          onChange={(value) => setLang(value)}
        >
          <Select.Option value="en" key="1" >en</Select.Option>
          <Select.Option value="fr" key="2">fr</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Title"
        name="title"
        rules={[{ required: true, message: 'Plese input video title!' }]}
      >
        <Input value={title} onChange={handleInput} />
      </Form.Item>
      <Form.Item label="Type"
        name="type"
        rules={[{ required: true, message: 'Plese select assessment type!' }]}
      >
        <Select
          onChange={(value) => setType(value)}
        >
          <Select.Option value="1" key="1" >Time Up-and-Go</Select.Option>
          <Select.Option value="2" key="2">1-minute sit-to-stand</Select.Option>
          <Select.Option value="3" key="3">SPPB 5 Sit-to-stand</Select.Option>
          <Select.Option value="4" key="4">SPPB Gait speed</Select.Option>
          <Select.Option value="5" key="5">SPPB Balance</Select.Option>
          <Select.Option value="6" key="6">6-minute walk test</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Description" name="description">
        <Editor
         
          editorStyle={{ border: "1px solid #ccc", height: '100px' }}
          editorState={descriptionEditor}

          // toolbarClassName='tollbarClassName'
          // wrapperClassName='wrapperClassName'
          onEditorStateChange={(editorState) => setDescriptionEditor(editorState)}
          onBlur={() => {
            setDescription(getDataFromEditor(descriptionEditor))
          }}
        />
      </Form.Item>

      <Form.Item label="Thumbnail"
        name="avatar"
      >
        <Upload  {...funcpic} accept="image/*"
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          onChange={handleSelectPic}
          maxCount={1}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              style={{
                width: '120px',
                height: '120px'
              }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
        
      </Form.Item>
      <Form.Item label="Video"
        // name="filename"
        rules={[{ required: true, message: 'Plese select video file!' }]}
      >
        <Upload  {...func} accept="video/*"
          onChange={handleSelectVideo}
        >
          <Button icon={<UploadOutlined />}>Select Video File</Button>
        </Upload>
        
      </Form.Item>
      <Form.Item>
        <div style={{ align: 'center' }}>

          <Button onClick={hanleClose} disabled={uploading}>Close</Button>
          <Button type="primary"
            onClick={handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{
              marginLeft: 16,
            }}
          >
            {uploading ? 'Uploading' : 'Upload'}
          </Button>
        </div>
      </Form.Item>
    </Form>


  )
})
export default AVideoUpload
