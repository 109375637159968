import React,{useEffect, useState,useRef} from 'react'
import { Space, Table, Button, Modal, message,Switch  } from 'antd';

import config from '../../config';
import * as iwareUtils from "../../util/common"

import UserAdd from '../../components/user/UserAdd';
import UserUpdate from '../../components/user/UserUpdate';

import { useHistory, useParams,Redirect } from 'react-router-dom'
import {EditOutlined,DeleteOutlined,ExclamationCircleOutlined} from '@ant-design/icons'
import {setcollapsed,setcurrentoprtitle} from '../../redux/MvpReducer'
import { useDispatch,useSelector } from 'react-redux';
import adminService from '../../services/adminService';

export default function AdminList() {
    const [dataSource,setdataSource]=useState([])
    const [isUploadModalOpen,setisUploadModalOpen]=useState(false)
    const [isUpdateModalOpen,setisUpdateModalOpen]=useState(false)
    const [currentVideoID,setcurrentVideoID]=useState("")
    const uploadForm=useRef(null)
    const dispatch = useDispatch()
    const columns = [
        {
          title: 'User Account',
          dataIndex: 'username',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          width:'300px',
          align: 'center',
          // render: (picurl) => (
          //   <img style={{borderRadius:'8px'}} src={picurl} height="120px" width="120px" />
          // ),
        },
        {
          title: 'User Type',
          dataIndex: 'type',
        //   key: 'age',
          width:'130px',
          align: 'center',
          render: (type) => (
            type===0?'SuperAdmin':type===1?'Adminstrator':'Kinesiologist'
          ),
        },
        {
          title: 'Active Status',
          dataIndex: 'isactive',
          align: 'center',
          width:'130px',
          render: (isactive,record) => (
            // item.type=="1"?'Exercise':'Warm up'
            <Switch checkedChildren="On" unCheckedChildren="Off" defaultChecked={isactive===1?true:false} onClick={(e)=>{disableAdmin(record.id,e)}} disabled={record.type===0?true:false}/>
          ),
        //   key: 'address',
        },
        {
          title: 'Action',
          key: 'action',
          align: 'center',
          render: (item) => (
            <Space size="middle">
              <Button size="small" onClick={()=>handlePopupUpdate(item.id)} disabled={item.type===0?true:false}>Update</Button>
              <Button size="small" onClick={()=>handlePWDReset(item.id)}>Reset Password</Button>
              <Button size="small" onClick={()=>confirm(item)} type="primary" danger disabled={item.type===0?true:false}>Delete</Button>
            </Space>
          ),
        },
      ];
      // const browseVideo=(id)=> {
      //   navigate.push(`/video/videolist/${id}`)
      // }
      const handlePWDReset=(id) =>{
        Modal.confirm({
            title: `Reset Password to '${config.resetPasswordString}'`,
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure you want to reset the password for this admin? 
            If you choice 'Yes', the password will be reset to '${config.resetPasswordString}'`,
            okText: 'Yes',
            cancelText: 'No',
            onOk(){
                resetPWD(id)
            }
          });
      }
      const confirm=(item) =>{
        Modal.confirm({
            title: 'Delete Confirm',
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure you want to delete this admin account?`,
            okText: 'Yes',
            cancelText: 'No',
            onOk(){
                deleteMethod(item)
                // console.log(id)
            }
          });
      }
      const deleteMethod=async (item)=>{
        
        let list=dataSource.filter(data=>data.id!==item.id)
        setdataSource([...list])
        const res=await adminService.deleteAdminByID(item.id)
        if(res.status===0){
          message.success('delete successfully.')
        }else{
          message.error('delete failed!')
        }
      }
      const resetPWD=async (id)=>{
        
        const res=await adminService.resetAdminPWDByID(id)
        if(res.status===0){
          message.success(`The password has been reset to '${config.resetPasswordString}'`)
        }else{
          message.error('reset failed!')
        }
      }
    useEffect(()=>{
        dispatch(setcurrentoprtitle(" Administrator List"))
    },[])
    useEffect(()=>{
      
      fetchData()
    },[])
    const fetchData=async () =>{
      const res= await adminService.getAdminList()
      if(res.code===0){
        setdataSource(res.data)
      }else{
        message.error(res.message)
      }
    }
    const handleCancel=()=>{
        setisUploadModalOpen(false)
        uploadForm.current.setFieldsValue({username:"",type:"",email:""})
    }
    const handleCancelUpdate=()=>{
      setisUpdateModalOpen(false)
      
    }

    const handlePopupUpload=()=>{
        setisUploadModalOpen(true)
    }
    const handlePopupUpdate=(id)=>{
        setcurrentVideoID(id)
        setisUpdateModalOpen(true)
    }
    const handleRefresh=()=>{
      fetchData()
    }
    const disableAdmin=async (id,status)=>{
      const res = await adminService.disableAdminByID(id,status)
      console.log(res)
      if(res.status===1){
        message.error(res.message)
      }
    }
    
  return (
    <div>
      {
        iwareUtils.hasPermission(['0'])? (
          <div>
            <Modal title="New Administrator" open={isUploadModalOpen}  closable={false} 
              footer={null
                  // [<Button type="primary" onClick={handleCancel}>Close</Button>,
                  // <Button type="primary" onClick={handleUploadVideo}>Upload</Button>]
              }
              width="650px"
            >
              <UserAdd  handleCancel={handleCancel} handleRefresh={handleRefresh} ref={uploadForm}></UserAdd>
            </Modal>
        <Modal title="Admin Update" open={isUpdateModalOpen}  closable={false} 
            footer={null
                // [<Button type="primary" onClick={handleCancel}>Close</Button>,
                // <Button type="primary" onClick={handleUploadVideo}>Upload</Button>]
            }
            width="650px"
        >
            <UserUpdate handleCancel={handleCancelUpdate} adminID={currentVideoID} handleRefresh={handleRefresh} ></UserUpdate>
        </Modal>
        <Button type="primary" onClick={handlePopupUpload} style={{marginBottom:'10px'}}>Add new admin</Button>
        {/* <ReactPlayer url='https://www.youtube.com/watch?v=LBQvUXoTHp4' height="180px" width="320px" /> */}
    <Table columns={columns} dataSource={dataSource} scroll={{y:'calc(100vh - 310px)'}}
        pagination={{
            pageSize: 8,
            // hideOnSinglePage:true
        }}
        rowKey={(item)=>item.id}
    />
    
    </div>
        ):(
          <Redirect to='/unauthorized'/>
        )
      
  }
   </div>  
  )
}
