import React, { useState, forwardRef, useRef, useEffect } from 'react';
import { Form, Input, Button, Select, message,  } from 'antd';

import adminService from '../../services/adminService'
import config from '../../config';

export default function UserUpdate(props) {
  
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");

  const curRef=useRef(null)
  useEffect(() => {
    adminService.getAdminByID(props.adminID).then(res=>{
      if(res.status===0){
        const adminInfo=res.data[0]
        curRef.current.setFieldsValue({
          username:adminInfo.username,
          // repetitions:vinfo.repetitions,
          type:adminInfo.type===1?'Administrator':'Kinesiologist',
          email:adminInfo.email
      })
      }
      console.log(res)
    })
    console.log(props)
  },[props])
  const hanleClose = () => {
  
    props.handleCancel()
  }
  
  const handleInput = (evt) => {
    setTitle(evt.target.value)
  }
  const handleInputEmail = (evt) => {
    setEmail(evt.target.value)
  }
  
  const handleAdd = async () => {
    
    curRef.current.validateFields().then(res => {
      // console.log(res.type)
      if(res.email===undefined){
        res.email=''
      }
      if(res.type==='Administrator'){
        res.type=1
      }else{
        res.type=2
      }
      updateAdmin(props.adminID,res)
    }).catch(err => { console.log(err) })

  };
  const updateAdmin=async (adminid,userinfo)=>{
    const res= await adminService.updateAdminByID(adminid,userinfo)
    if(res.status ===1){
      message.error(res.message)
    }else{
      message.success('Updated successfully.')
      props.handleRefresh()
      props.handleCancel()
    }
  }
  
  return (
    <Form
      labelCol={{
        span: 5,
      }}
      wrapperCol={{
        span: 18,
      }}
      layout="horizontal"
      style={{
        maxWidth: 900,
        
      }}
      ref={curRef}
    >

      <Form.Item label="User Account"
        name="username"
        rules={[{ required: true, message: 'Plese input user account!' }]}
      >
        <Input value={title} onChange={handleInput} readOnly/>
      </Form.Item>
      <Form.Item label="User Type"
        name="type"
        rules={[{ required: true, message: 'Plese select User type!' }]}
      >
        <Select
          onChange={(value) => setType(value)}
        >
          <Select.Option value="Administrator" key="1" >Administrator</Select.Option>
          <Select.Option value="Kinesiologist" key="2">Kinesiologist</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Email"
        name="email"
      >
        <Input value={email} onChange={handleInputEmail} />
      </Form.Item>
      {/* <Form.Item label="Default Password"
      >
        <div style={{fontWeight:'600',color:'red',fontSize:'20px'}}>{config.resetPasswordString}</div>
      </Form.Item> */}
      <Form.Item>
        <div style={{ align: 'center' }}>

          <Button onClick={hanleClose} >Close</Button>
          <Button type="primary"
            onClick={handleAdd}
            style={{
              marginLeft: 16,
            }}
          >
            Update
          </Button>
        </div>
      </Form.Item>
    </Form>


  )
}

