import {createSlice} from '@reduxjs/toolkit'

const MvpReducer=createSlice(
    {
        name:'globalconfig',
        initialState:{sidemenuFlag:true,currentoprtitle:""},
        reducers:{
            
            setcollapsed:(state,actions)=>{
                state.sidemenuFlag=!state.sidemenuFlag
            },
            setcurrentoprtitle:(state,actions)=>{
                state.currentoprtitle=actions.payload
            }
        }
    }
)
export const {setcollapsed,setcurrentoprtitle} = MvpReducer.actions
export default MvpReducer